// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import storage from '../../../framework/src/StorageProvider';
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}
interface NotificationListData{
    id:number;
    heading:string;
    profilePic:string;
    name:string;
    message:string;
    time:string;
}
interface S {
    notificationData: any;
    token: string;
    id: number;
    avatar: string;
    title: string;
    description: string;
    time: string;
    notifications: NotificationListData[];
    currentPage: number;
    totalPages: number;
    totalCount: number;
    paginatednNotifications: NotificationListData[];
    itemsPerPage:number;
    loader:boolean;
    isMobileView:boolean;
}

interface SS {
    id: any;
}

export default class NotificationListController extends BlockComponent<
    Props,
    S,
    SS
> {
    getNotificationListId: string = '';
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            notificationData: [{ id: 1, listText: "New Chat", title: 'Jane Cooper', sender: "I've just received an email from...", time: '1h' },
            { id: 1, title: 'Builder.ai', listText: "New Job Offer", sender: 'Substitute Teacher', time: '1h' },
            { id: 1, title: 'Builder.ai', listText: "New Job Offer", sender: 'Substitute Teacher', time: '1h' },
            { id: 1, title: 'Jane Cooper', listText: "New chat", sender: "I've just received an email from...", time: '1h' },
            { id: 1, title: 'Builder.ai', listText: "New Job offer", sender: 'Substitute Teacher', time: '1h' },
            { id: 1, title: 'Jane Cooper', listText: "New Chat", sender: "I've just received an email from...", time: '1h' },
            { id: 1, title: 'Jane Cooper', listText: "New Chat", sender: "I've just received an email from...", time: '1h' },
            { id: 1, title: 'Builder.ai', listText: "New Job Offer", sender: 'Substitute Teacher', time: '1h' },
            ],
            notifications: [],
            token: "",
            id: 1,
            avatar: "",
            title: "",
            description: "",
            time: "",
            currentPage: 1,
            totalPages: 1,
            paginatednNotifications: [], 
            totalCount: 0,
            itemsPerPage:10,
            loader:false,
            isMobileView:window.innerWidth <= 571
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    async componentDidMount() {
        super.componentDidMount();
        this.setState({loader:true})
        this.getNotificationList()
        window.addEventListener("resize", this.handleResize);
    }
    async componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
      }
    async receive(from: string, message: Message) {
        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            runEngine.debugLog("Message Recived", message);

            let token = message.getData(getName(MessageEnum.SessionResponseToken));
            this.setState({ token: token });
        } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId == this.getNotificationListId)
            {
                this.handleErrorResponse(responseJson)
                    const transactionsData = responseJson.data;
                    const totalCount = transactionsData.length;
    
                    const mappedNotifications = responseJson?.data?.map((item: any) => ({
                        id:item.id,
                        heading: item.attributes?.remarks,
                        profilePic: item.attributes.sender_profile_image?.url ?? '',
                        name: item.attributes?.remarks!='New Job Offer' ?`${item.attributes?.sender_first_name} ${item.attributes?.sender_last_name}` : 'Builder.ai',
                        message: item.attributes?.remarks!='New Job Offer' ? item.attributes?.chat_message : item.attributes?.job_title,
                        time: item.attributes?.time_ago,
                      }));
                      this.setState({notifications:mappedNotifications})

                      const totalPages = Math.ceil(mappedNotifications.length / this.state.itemsPerPage);

                      this.setState({
                        notifications: mappedNotifications,
                          totalPages: totalPages,
                          totalCount: totalCount,
                      }, () => {
                          this.paginateTransactions();
                          this.setState({loader:false}) 
                      });
                }
    }

    }
    handleErrorResponse(responseJson:any){
        if (responseJson?.errors) {
            this.setState({ loader: false })
            return null
        }
    }
    paginateTransactions = () => {
        const { currentPage, notifications } = this.state;
        const startIndex = (currentPage - 1) *this.state.itemsPerPage;
        const paginatednNotifications = notifications.slice(startIndex, startIndex + this.state.itemsPerPage);

        this.setState({ paginatednNotifications });
    };
    handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
        this.setState({ currentPage: newPage }, this.paginateTransactions);
    }
    
    handleBack = () => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData(
            getName(MessageEnum.NavigationTargetMessage),
            'JobListing'
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        const raiseMessage: Message = new Message(
            getName(MessageEnum.NavigationPayLoadMessage)
        );
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(message);
    }
    getNotificationList=async ()=>{
        this.setState({loader:true})

        const header = {
          token: await storage.get("loginToken"),
      };
    
      const getTeacherForm = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.getNotificationListId = getTeacherForm.messageId;
    
      getTeacherForm.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_push_notifications/push_notifications/show_notification_list`
      );
    
      getTeacherForm.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
      );
    
      getTeacherForm.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          `GET`
      );
    
      runEngine.sendMessage(getTeacherForm.id, getTeacherForm);
      }

      handleResize = () => {
          this.setState({ isMobileView: window.innerWidth <= 571});
      };

}
// Customizable Area End
