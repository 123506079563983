import React from "react";

// Customizable Area Start

import Footer from "../../../components/src/Footer.web";
import Navbar from "../../../components/src/Navbar.web";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { styled, Box, Typography } from "@material-ui/core";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <CustomNavbar>
          <Navbar navigation={this.props.navigation} id={""} NaviagationPage={""} />
        </CustomNavbar>
        <MobileNabar>
          <ArrowBackIosIcon data-test-id="navigateToHome" onClick={this.navigateSettingMobile} style={{ color: "#fff" }} />
          <Typography style={webStyle.mobilenav}>Terms and Conditions</Typography>
        </MobileNabar>
        <MainBox>
          <Navigation>
            <Typography data-test-id="navigateToHome" onClick={this.navigateToHome} style={{...webStyle.home , cursor:"pointer"}}>Home</Typography>
            {this.state.istokenPresent && <>
            <ArrowForwardIosIcon style={{...webStyle.forwardArrow}} />
            <Typography style={{...webStyle.home , cursor:"pointer"}} data-test-id="navigateSetting" onClick={this.navigateSetting}>Settings</Typography>
            </>}
            <ArrowForwardIosIcon style={webStyle.forwardArrow} />
            <Typography style={webStyle.joblisting}>Terms and conditions</Typography>
          </Navigation>
          <ContentBox>
            <Heading style={webStyle.heading}>Terms and conditions</Heading>
            <PrivacyDes> <div dangerouslySetInnerHTML={{ __html: this.state.termsCondsData }} /></PrivacyDes>
          </ContentBox>
        </MainBox>
        <CustomFooter>
          <Footer navigation={this.props.navigation} id={""} NaviagationPage={""} />
        </CustomFooter>
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const MainBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: 94,
  paddingBottom: 120,
  position: 'relative',
  "@media only screen and (max-width: 605px)": {
    paddingLeft: 20,
    paddingRight: 20,
  },
  "@media only screen and (max-width: 571px)": {
    paddingTop: 32,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 45
  }
})
const Heading = styled(Typography)({
  color: '#1E293B',
  fontFamily: "Inter",
  fontWeight: 700,
  fontSize: '30px',
  marginBottom: 24,
  lineHeight: '40px',
  "@media only screen and (max-width: 571px)": {
      display: 'none',
  }
})
const PrivacyDes = styled(Typography)({
  fontSize: '16px',
  color: '#334155',
  lineHeight: '25.6px',
  fontFamily: 'Inter',
  fontWeight: 400,
  marginTop: '24px',
  "@media only screen and (max-width: 620px)": {
      fontSize: '14px',
      marginTop: '20px',
  },
})
const Navigation = styled(Box)({
  display: "flex",
  gap: "10px",
  position: "absolute" as "absolute",
  top: 32,
  left: 114,
  "@media only screen and (max-width: 571px)": {
    display: 'none',
  }
})
const CustomNavbar = styled(Box)({
  "@media only screen and (max-width: 571px)": {
    display: 'none',
  }
})
const MobileNabar = styled(Box)({
  display: 'none',
  "@media only screen and (max-width: 571px)": {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#395169',
    padding: 20,
    position: 'relative',
  }
})
const CustomFooter = styled(Box)({
  "@media only screen and (max-width: 571px)": {
    display: 'none',
  }
})
const ContentBox = styled(Box)({
  width: '826px',
  "@media only screen and (max-width: 1020px)": {
    width: 699
  },
  "@media only screen and (max-width: 820px)": {
    width: 529,
  },
  "@media only screen and (max-width: 605px)": {
    width: 'unset',
  }
})
const webStyle = {
  joblisting: {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  home: {
    color: "#475569",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  forwardArrow: {
    color: "#475569",
    height: "15px",
    marginTop: 4
  },
  mobilenav: {
    color: '#fff',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    textAlign: 'center' as 'center',
    left: "13%",
    position: 'absolute' as 'absolute',
    right: '16%',
  },
  heading: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '30px',
    fontWeight: 700,
    lineHeight: '40px',
  },
  content: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '25.6px',
    marginTop: '24px'
  },
  suhHead: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: "25.6px",
    marginTop: 32
  }
}

// Customizable Area End
