// Customizable Area Start
import React from "react";
import JobListingDetailsController ,{JobWorkingDay,Props} from "../../joblisting/src/JobListingDetailsController";
import { Avatar, Box, Divider,Typography ,MenuItem ,Menu} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { backIcon,educationLogo, experienceLogo, locationLogo, rightArrow } from "../../joblisting/src/assets";
import Footer from "../../../components/src/Footer.web";
import { school } from "./assets";
import Navbar from "../../../components/src/Navbar.web";
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import MoreVertIcon from '@material-ui/icons/MoreVert';
export const configJSON = require("../../joblisting/src/config");

class JobDetails extends JobListingDetailsController {
  constructor(props: Props) {
    super(props);
  }
  formatTime = (time: string) => {
    if (!time) return "";
    const [hours, minutes] = time.split(":");
    const period = parseInt(hours) >= 12 ? "PM" : "AM";
    const formattedHours = parseInt(hours) % 12 || 12;
    return `${formattedHours}:${minutes} ${period}`;
  };

  renderStartEndTime = (item: JobWorkingDay) => {
    return (
      <>
        {!item.full_day && (
          <>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                textAlign: "end",
              }}
            >
              <Typography style={{ ...webStyleNew.preTime }}>
                Start Time:
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color: "#395169",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 600,
                  lineHeight: "24px",
                }}
              >
                {item.start_time}
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                textAlign: "end",
              }}
            >
              <Typography style={{ ...webStyleNew.preTime }}>
                End Time:
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color: "#395169",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 600,
                  lineHeight: "24px",
                }}
              >
                {item.end_time}
              </Typography>
            </Box>
          </>
        )}
      </>
    );
  };


  getSorted(data:any) {
    return data ? 
    [...data].sort((a, b) => a?.working_day_id - b?.working_day_id) 
    : [];
  }
  
  doSort() {
    return this.getSorted(this.state.details.job_working_days) || [];
  }
  render() {
    const { details, anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const sortedData = this.doSort()
    return (
      <>
        <MainBox>
          <NavbarBox>
            <Navbar
              id=""
              navigation={this.props.navigation}
              NaviagationPage={"Home"}
            />
          </NavbarBox>
          <NavbarMobile>
            <img
              data-test-id="btnBack"
              onClick={this.handleBack}
              style={webStyle.backIcon}
              src={backIcon}
              alt=""
            />
            <NavbarTitle>Job Details</NavbarTitle>
          </NavbarMobile>
          <BreadcrumbBox>
            <BreadcrumbTitle
              data-test-id="btnNavigate"
              onClick={() => this.handleNavigation("JobListing", false)}
            >
              {configJSON.homeTxt}
            </BreadcrumbTitle>
            <img style={webStyle.rightIcon} src={rightArrow} alt="" />
            <BreadcrumbTitle
              data-test-id="btnNavigate"
              onClick={() => this.handleNavigation("JobCreated", false)}
            >
              Jobs Created
            </BreadcrumbTitle>
            <img style={webStyle.rightIcon} src={rightArrow} alt="" />
            <BreadcrumbTitleLast>Job Details</BreadcrumbTitleLast>
          </BreadcrumbBox>
          <JobDetailsTopBox>
            <Box style={webStyle.jobTitleMain}>
              <Box>
                <MainTitle>{this.state.details.job_title}</MainTitle>
                <MainCompanyName>
                  {this.state.details.organization}
                </MainCompanyName>
              </Box>
              <DisplayForDesktop>
                <ImageAvatar alt="company_logo" src={school} />
              </DisplayForDesktop>
              <DisplayForMobile data-test-id="btnSaveUnsave"></DisplayForMobile>
            </Box>
            <JobTitleTopMiddleSection>
              <JobTitleTopMiddleSmallBox>
                <Box style={webStyle.jobTitleMiddleInnerBox}>
                  <img src={experienceLogo} alt="" />
                  <JobTitleTopCategoryTypo>
                    {configJSON.experienceTxt}
                  </JobTitleTopCategoryTypo>
                </Box>
                <JobTitleTopCategoryDetail>
                  {details.experience_required}
                </JobTitleTopCategoryDetail>
                <MobileIcon
                  onClick={this.handleOpen}
                  data-test-id="handleOpenId"
                >
                  <MoreVertIcon />
                </MobileIcon>
              </JobTitleTopMiddleSmallBox>
              <JobTitleTopMiddleSmallBox>
                <Box style={webStyle.jobTitleMiddleInnerBox}>
                  <img src={educationLogo} alt="" />
                  <JobTitleTopCategoryTypo>
                    {configJSON.educationLevelTxt}
                  </JobTitleTopCategoryTypo>
                </Box>
                <JobTitleTopCategoryDetail>
                  {details.education_level}
                </JobTitleTopCategoryDetail>
              </JobTitleTopMiddleSmallBox>
              <JobTitleTopMiddleSmallBox>
                <Box style={webStyle.jobTitleMiddleInnerBox}>
                  <img src={locationLogo} alt="" />
                  <JobTitleTopCategoryTypo>
                    {configJSON.locationTxt}
                  </JobTitleTopCategoryTypo>
                </Box>
                <JobTitleTopCategoryDetail>
                  {details.city}, {details.state}, {details.country}
                </JobTitleTopCategoryDetail>
              </JobTitleTopMiddleSmallBox>
              <PostedTimeMobile>
                <Box style={webStyle.postedAtBox}>
                  {configJSON.postedTxt}
                  {configJSON.colonTxt}
                  <Typography style={webStyle.postedAtText}>
                    Posted At {details.created_at}
                  </Typography>
                </Box>
              </PostedTimeMobile>
            </JobTitleTopMiddleSection>
            <JobDetailsBottomDivider />
            <JobTitleTopBottomSection>
              <Box style={webStyle.postedAtBox}>
                {configJSON.postedTxt}
                {configJSON.colonTxt}
                <Typography style={webStyle.postedAtText}>
                  {details.created_at}
                </Typography>
              </Box>
              <Box style={{ display: "flex", gap: "8px" }}>
                <Box onClick={this.handleOpen} style={{cursor:"pointer"}} data-test-id="handleOpenId">
                  <MoreVertIcon  style={{cursor:"pointer"}}/>
                </Box>
              </Box>
              <Dropdown
                anchorEl={anchorEl}
                open={open}
                onClose={this.handleClose}
                style={{ transform: "translate(-180px , -40px)" }}
              >
                <MenuItem
                  style={webStyle.menuList}
                  data-test-id="edit"
                  onClick={() => this.handleEditClick(details)}
                >
                  <EditIcon /> Edit
                </MenuItem>
                <MenuItem
                  style={{ ...webStyle.menuList, color: "#dc2626" }}
                  data-test-id="delete"
                  onClick={() => this.handleDeleteClick(details.id)}
                >
                  <DeleteOutlineIcon /> Delete
                </MenuItem>
              </Dropdown>
            </JobTitleTopBottomSection>
          </JobDetailsTopBox>
          <PostedByBox>
            <JobDescTitle>{configJSON.positionTitle}</JobDescTitle>
            {sortedData?.map((item, index) => (
              <Box key={index} style={{ marginTop: "10px" }}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    textAlign: "end",
                  }}
                >
                  <Typography style={{ ...webStyleNew.prefOption }}>
                    {this.state.days[item.working_day_id]}
                  </Typography>
                  <Typography
                    style={{
                      color: "#395169",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: 600,
                      lineHeight: "24px",
                    }}
                  >
                    {item.full_day ? "Full Day" : ""}
                  </Typography>
                </Box>
                <>{this.renderStartEndTime(item)}</>
                <Box></Box>
              </Box>
            ))}
          </PostedByBox>
          <PostedByBox>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                textAlign: "end",
              }}
            >
              <Typography style={{ ...webStyleNew.prefOption }}>
                Start Date
              </Typography>
              <Typography
                style={{
                  color: "#395169",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 600,
                  lineHeight: "24px",
                }}
              >
                {this.formatDate(details.start_date)}
              </Typography>
            </Box>

            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                textAlign: "end",
              }}
            >
              <Typography style={{ ...webStyleNew.prefOption }}>
                End Date
              </Typography>
              <Typography
                style={{
                  color: "#395169",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 600,
                  lineHeight: "24px",
                }}
              >
                {this.formatDate(details.end_date)}
              </Typography>
            </Box>
          </PostedByBox>
          <JobDescriptionMainBox>
            <JobDescTitle>{configJSON.jobDescriptionTxt}</JobDescTitle>
            <JobDescJobTitleBox>
              {configJSON.jobTitleTxt}
              {configJSON.colonTxt}
              <JobDescJobTitle>{details.job_title}</JobDescJobTitle>
            </JobDescJobTitleBox>
            <AboutOrg>
              {" "}
              <div
              style={{wordBreak:"break-all" , textWrap:"wrap" , 
                wordWrap:"break-word"}}
                dangerouslySetInnerHTML={{ __html: details.job_description }}
              />
              {details.about_organization}
            </AboutOrg>
            <DividerForMobile />
          </JobDescriptionMainBox>
          <AboutOrganizationMainBox>
            <AboutOrganizationTitle>
              {configJSON.aboutOrganization}
            </AboutOrganizationTitle>
            <Box style={webStyle.aboutOrganizationTop}>
              <ImageAvatar src={school} alt="" />
              <AboutOrgDetailBox>
                <AboutOrgName>{details.organization}</AboutOrgName>
                <AboutOrgLocation>
                  {details.city}, {details.state}, {details.country}
                </AboutOrgLocation>
              </AboutOrgDetailBox>
            </Box>
            <AboutOrg>{details.about_organization}</AboutOrg>
          </AboutOrganizationMainBox>
          <FooterBox>
            <Footer navigation={undefined} id={""} NaviagationPage={""} />
          </FooterBox>
        </MainBox>
      </>
    );
  }
}

export default JobDetails;
const webStyle = {
  backIcon: {
    cursor: "pointer",
  },
  appliedCheckMarkImg: {
    width: "100%",
    height: "100%",
  },
  popup: {
    backgroundColor: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    width: "332px",
    padding: "10px 10px 10px 10px",
    gap: "8px",
    borderFadius: "8px",
    boxShadow: "0px 6px 15px -3px #00000026",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#0F172A",
  },
  rightIcon: {
    margin: "0 15px 0 15px",
  },
  viewAllBox: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  viewAllRightIcon: {
    height: "10px",
    width: "8spx",
    marginLeft: "7.6px",
  },
  menuList: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  jobTitleMain: {
    display: "flex", justifyContent: "space-between",alignItems: "center"},
  jobTitleMiddleInnerBox: {
    display: "flex",alignItems: "center" },
  postedAtBox: {
    color: "#64748B",fontFamily: "Inter", fontSize: "12px",fontWeight: 400,lineHeight: "18px",},
  postedAtText: {
    display: "inline-block",fontFamily: "Inter", fontSize: "12px", fontWeight: 600,lineHeight: "18px",color: "#4788B7",marginLeft: "4px",},
  saveJobBox: {
    display: "flex", alignItems: "center", color: "#395169",fontFamily: "Inter",fontSize: "14px",fontWeight: 500,cursor: "pointer",},
  savedIcon: {marginRight: "4px",},
  savedIconForMobile: {cursor: "pointer", },
  sendMessageBtn: {
    height: "44px",gap: "8px", borderRadius: "8px",color: "#395169",border: "1px solid #395169",background: "#FFFFFF",cursor: "pointer", padding: "10px 24px 10px 24px", fontFamily: "Inter",fontSize: "16px",fontWeight: 500,},
  aboutOrganizationTop: {display: "flex",},
  applyForJobBtn: {
    width: "100%",height: "100%",    border: "none",    borderRadius: "8px",    backgroundColor: "#395169",    color: "#FFFFFF",    fontFamily: "Inter",    fontSize: "16px",    fontWeight: 700,cursor: "pointer", },
  applyForJobMobileBtn: {
    border: "none",
    height: "100%",
    width: "100%",
    padding: "10px 16px 10px 16px",
    borderRadius: "8px",
    backgroundColor: "#395169",
    fontFamily: "Inter",
    color: "#FFFFFF",
    fontWeight: 700,
    fontSize: "14px",    cursor: "pointer",  },

  sendMsgBtnMobile: {    height: "100%",    width: "100%",    border: "1px solid #395169",    padding: "10px 16px 10px 16px",    borderRadius: "8px",
    backgroundColor: "#FFFFFF",    fontFamily: "Inter",    color: "#395169",    fontSize: "14px",    cursor: "pointer",    fontWeight: 700,  },
    jobShortDetailBox: {    display: "flex",    alignItems: "center",
  },

  viewDetailsBtn: {    height: "32px",    padding: "6px 16px 6px 16px",    borderRadius: "8px",    color: "#395169",    border: "1px solid #395169",    background: "none",    cursor: "pointer",},
};

const MainBox = styled(Box)({ backgroundColor: "#F8F8F8"});

const BreadcrumbBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  margin: "36px 0 0 120px",
  "@media only screen and (max-width: 924px)": {
    margin: "36px 0 0 80px",
  },
  "@media only screen and (max-width: 816px)": {
    margin: "36px 0 0 40px",
  },
  "@media only screen and (max-width: 414px)": {
    display: "none",
  },
});

const BreadcrumbTitle = styled(Box)({
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "22px",
  color: "#475569",
  cursor: "pointer",
  "&:hover": {
    textDecoration: "none",
    textDecorationColor: "#475569",
  },
});

const MobileIcon = styled(Box)({
  "@media only screen and (min-width: 414px)": {
    display: "none",
  },
});

const BreadcrumbTitleLast = styled(Box)({
  fontSize: "14px",
  lineHeight: "22px",
  fontWeight: 400,
  color: "#475569",
  fontFamily: "Inter",
});

const Dropdown = styled(Menu)({
  "& .MuiPopover-paper": {
    marginTop: "50px",
    border: "1px solid #E2E8F0",
    borderRadius: "8px",
  },
  "& .MuiPaper-elevation8": {
    boxShadow: "0px 4px 8px 0px #00000008",
    width: 180,
  },
});

const JobDetailsTopBox = styled(Box)({
  backgroundColor: "#FFFFFF",  "box-shadow": "rgba(0, 0, 0, 0.15) 0px 2px 8px",  maxWidth: "728px",  margin: "auto",  marginTop: "32px",  padding: "32px 32px 32px 32px",  borderRadius: "16px",
  "@media only screen and (max-width: 414px)": {
    "box-shadow": "none",    borderRadius: "0",    paddingTop: "0px",
  },
  "@media only screen and (max-width: 620px)": {
    maxWidth: "360px",padding: "16px 16px 16px 16px",
  },
  "@media only screen and (max-width: 816px)": {
    maxWidth: "528px", padding: "24px 24px 24px 24px",
  },
});

const MainTitle = styled(Typography)({  fontFamily: "Inter",  fontSize: "24px",  lineHeight: "32px",  fontWeight: 600,  color: "#0F172A",
  "@media only screen and (max-width: 620px)": {
    fontSize: "18px",    lineHeight: "28px",
  },
  "@media only screen and (max-width: 816px)": {
    fontSize: "20px",    lineHeight: "30px",
  },
});

const MainCompanyName = styled(Typography)({  fontFamily: "Inter",  fontSize: "16px",  lineHeight: "24px",  fontWeight: 400,  marginTop: "8px",  color: "#475569",
 
  "@media only screen and (max-width: 414px)": { display: "none",  },
  "@media only screen and (max-width: 620px)": {
    fontSize: "14px",
    lineHeight: "20px",    marginTop: "4",
  },
  "@media only screen and (max-width: 816px)": {
    fontSize: "14px",    lineHeight: "22px",
  },
});

const JobTitleTopMiddleSection = styled(Box)({  display: "flex",  alignItems: "center",  marginTop: "32px",
"@media only screen and (max-width: 620px)": {    display: "block",  },
"@media only screen and (max-width: 816px)": {    display: "block",  },
  "@media only screen and (max-width: 414px)": {    display: "flex",    margin: "auto",    alignItems: "center",    justifyContent: "center",    flexDirection: "column-reverse",    position: "relative",    marginTop: "16px",  },
});

const JobTitleTopMiddleSmallBox = styled(Box)({  marginRight: "48px",
  "@media only screen and (max-width: 816px)": {    display: "flex",    alignItems: "center",    marginBottom: "8px",  },
  "@media only screen and (max-width: 620px)": {    marginBottom: "8px",    width: "100%",  },
  "@media only screen and (max-width: 414px)": {    margin: "auto",    marginBottom: "8px",  },
});

const PostedTimeMobile = styled(Box)({  display: "none",
  "@media only screen and (max-width: 414px)": {    position: "absolute",    bottom: "12px",    right: "0px",  },
});

const JobTitleTopCategoryTypo = styled(Typography)({  fontFamily: "Inter",  fontSize: "12px",  fontWeight: 500,  lineHeight: "18px",  color: "#64748B",  marginLeft: "8px",
  "@media only screen and (max-width: 816px)": {    width: "120px",  },
  "@media only screen and (max-width: 414px)": {    display: "none",  },
});

const JobTitleTopCategoryDetail = styled(Typography)({  marginTop: "8px",  fontFamily: "Inter",  fontSize: "16px",  fontWeight: 400,  lineHeight: "24px",  color: "#334155",  width: "100%",
  "@media only screen and (max-width: 816px)": {    margin: "0 0 0 8px",  },
  "@media only screen and (max-width: 620px)": {    margin: "0 0 0 8px",    fontSize: "14px",  },
});

const JobTitleTopBottomSection = styled(Box)({  display: "flex",  alignItems: "center",  justifyContent: "space-between",
  "@media only screen and (max-width: 414px)": {    display: "none",  },
});

const JobDetailsBottomDivider = styled(Divider)({  margin: "32px 0 32px 0",  
"@media only screen and (max-width: 414px)": {    margin: "16px 0 16px 0",  },
});

const PostedByBox = styled(Box)({  backgroundColor: "#FFFFFF",  "box-shadow": "rgba(0, 0, 0, 0.15) 0px 2px 8px",  maxWidth: "728px",  margin: "auto",  marginTop: "24px",  padding: "32px 32px 32px 32px",  borderRadius: "16px",
  "@media only screen and (max-width: 816px)": {    maxWidth: "528px",    padding: "24px 24px 24px 24px",  },
  "@media only screen and (max-width: 620px)": {    maxWidth: "360px",    padding: "16px 16px 16px 16px",  },
  "@media only screen and (max-width: 414px)": {    "box-shadow": "none",    borderRadius: "0",    marginTop: "0px",    paddingTop: "0",    paddingBottom: "0",  },
});

const ImageAvatar = styled(Avatar)({  width: "56px",  height: "56px",});

const JobDescriptionMainBox = styled(Box)({  backgroundColor: "#FFFFFF",  "box-shadow": "rgba(0, 0, 0, 0.15) 0px 2px 8px",  maxWidth: "728px",  margin: "auto",  marginTop: "24px",  padding: "32px 32px 32px 32px",  borderRadius: "16px",
  "@media only screen and (max-width: 816px)": {    maxWidth: "528px",    padding: "24px 24px 24px 24px",  },
  "@media only screen and (max-width: 620px)": {    maxWidth: "360px",    padding: "16px 16px 16px 16px",
  },
  "@media only screen and (max-width: 414px)": {    "box-shadow": "none",    borderRadius: "0",    paddingTop: "0",  },
  });

const JobDescTitle = styled(Typography)({  fontFamily: "Inter",  fontSize: "20px",  lineHeight: "28px",  fontWeight: 600,  color: "#1E293B",
  "@media only screen and (max-width: 816px)": {    fontSize: "18px",    lineHeight: "26px",  },
  "@media only screen and (max-width: 620px)": {    fontSize: "16px",    lineHeight: "26px",  },
});

const JobDescJobTitleBox = styled(Box)({  fontFamily: "Inter",  fontSize: "16px",  fontWeight: 600,  lineHeight: "25.6px",  color: "#334155",
  "@media only screen and (max-width: 620px)": {    fontSize: "14px",    lineHeight: "22px",  },
});

const JobDescJobTitle = styled(Typography)({  display: "inline-block",  fontSize: "16px",  fontFamily: "Inter",  lineHeight: "25.6px",  fontWeight: 400,  color: "#334155",  marginLeft: "4px",  marginTop: "24px",
  "@media only screen and (max-width: 620px)": {    fontSize: "14px",    lineHeight: "22px",    marginTop: "12px",  },
});

const AboutOrganizationMainBox = styled(Box)({  backgroundColor: "#FFFFFF",  "box-shadow": "rgba(0, 0, 0, 0.15) 0px 2px 8px",  maxWidth: "728px",  margin: "auto",
  marginTop: "24px",  padding: "32px 32px 32px 32px",  borderRadius: "16px",
  "@media only screen and (max-width: 816px)": {    maxWidth: "528px",    padding: "24px 24px 24px 24px",  },
  "@media only screen and (max-width: 620px)": {    maxWidth: "360px",    padding: "16px 16px 16px 16px",  },
  "@media only screen and (max-width: 414px)": {    "box-shadow": "none",    borderRadius: "0",    marginTop: "0px",    paddingTop: "0px",  },
});

const AboutOrganizationTitle = styled(Typography)({  fontWeight: 600,  lineHeight: "28px",  fontFamily: "Inter",  color: "#1E293B",  fontSize: "20px",  marginBottom: "24px",  
  "@media only screen and (max-width: 620px)": {    lineHeight: "24px",    marginBottom: "20px",    fontSize: "18px",  },
  "@media only screen and (max-width: 414px)": {    display: "none",  },});

const AboutOrgDetailBox = styled(Box)({  marginLeft: "16px",});

const AboutOrgName = styled(Typography)({  fontSize: "18px",  fontFamily: "Inter",  lineHeight: "26px",  fontWeight: 500,
  "@media only screen and (max-width: 620px)": {    fontSize: "16px",  },
});

const AboutOrgLocation = styled(Typography)({  marginTop: "4px",  color: "#64748B",  fontSize: "14px",  fontWeight: 400,  lineHeight: "22px",  fontFamily: "Inter",});

const AboutOrg = styled(Typography)({  fontSize: "16px",  color: "#334155",  lineHeight: "25.6px",  fontFamily: "Inter",  fontWeight: 400,  marginTop: "24px",
  "@media only screen and (max-width: 620px)": {    fontSize: "14px",    marginTop: "20px",  },
});

const DividerForMobile = styled(Divider)({  display: "none",
  "@media only screen and (max-width: 414px)": {    display: "block",    margin: "16px 0 16px 0",  },
});


const DisplayForDesktop = styled(Box)({
  "@media only screen and (max-width: 414px)": {    display: "none",  },
});

const DisplayForMobile = styled(Box)({  display: "none",  cursor: "pointer",  
"@media only screen and (max-width: 414px)": {    display: "block",  },
});

const FooterBox = styled(Box)({  marginTop: "120px",
  "@media only screen and (max-width: 620px)": {    fontSize: "80px",  },
  "@media only screen and (max-width: 571px)": {    display: "none",  },
});

const NavbarBox = styled(Box)({
  "@media only screen and (max-width: 571px)": {    display: "none",  },
});

const NavbarMobile = styled(Box)({  display: "none",
  "@media only screen and (max-width: 571px)": {
    display: "flex",    alignItems: "center",    backgroundColor: "#395169",    padding: "15px 0 15px 20px",    position: "relative",
  },
});

const NavbarTitle = styled(Box)({
  fontFamily: "Inter",
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "24px",
  color: "#FFFFFF",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
});

const webStyleNew = {
  confirmheading: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "25.8px",
    fontWeight: 700,
    lineHeight: "41.6px",
    marginTop: "22px",
  },
  confirmDelete: {
    color: "#DC2626",
    fontFamily: "Inter",
    fontSize: "21.6px",
    fontWeight: 700,
    lineHeight: "38.4px",
    textAlign: "center" as "center",
    textTransform: "none" as "none",
    marginTop: "10px",
    cursor: "pointer",
  },
  popHeading: {
    color: "#1E293B",
    fontFamily: "Inter" as "Inter",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "26px",
  },
  joblisting: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  home: {
    color: "#475569",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  forwardArrow: {
    color: "#395169",
    height: "15px",
    marginTop: 4,
  },
  profileInfo: {
    background: "#fff",
    padding: "32px",
    borderRadius: "16px",
  },
  heading: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "40px",
    marginBottom: "32px",
  },
  profilepic: {
    borderRadius: "50%",
    width: 120,
    height: 120,
    cursor: "pointer",
  },
  editText: {
    color: "#395169",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },

  profilepicMob: {
    borderRadius: "50%",
    width: 56,
    height: 56,
    cursor: "pointer",
  },
  name: {    color: "#1E293B",    fontFamily: "Inter",    fontSize: "24px",    fontweight: 600,    lineHeight: "32px",  },
  
  email: {    color: "#475569",    fontFamily: "Inter",    fontSize: "16px",    fontWeight: 400,    lineHeight: "24px",  },
  
  nameMob: {    color: "#1E293B",    fontFamily: "Inter",    fontSize: "18px",    fontweight: 600,    lineHeight: "32px",  },
  
  emailMob: {    color: "#475569",    fontFamily: "Inter",    fontSize: "16px",    fontWeight: 400,    lineHeight: "14px",  },
 
  countingBox: {    border: "1px solid #E2E8F0",    borderRadius: "8px",    textAlign: "center" as "center",    background: "#fff",    display: "flex",    alignItems: "center",    justifyContent: "space-between",  },
 
  profileStatusBox: {    background: "#EBF3C6",    borderRadius: "12px",    padding: "24px",    borderBottomLeftRadius: "unset",    borderBottomRightRadius: "unset",},
 
  profileCompletion: {    color: "#1E293B",    fontFamily: "Inter",    fontSize: "16px",    fontWeight: 700,    lineHeight: "24px",  },
 
  remaining: {    background:"linear-gradient(90.62deg, #4788B7 0%, #669CBA 42.9%, #C6DC68 100%)",    paddingTop: 5,    paddingLeft: 10,    paddingBottom: 5,    paddingRight: 10,    borderBottomLeftRadius: "12px",    borderBottomRightRadius: "12px",    display: "flex",    justifyContent: "space-between",  },
  
  addmore: {    color: "#334155",    fontFamily: "Inter",    fontSize: "12px",    fontWeight: 400,    lineHeight: "18px",  },
  
  text: {    color: "#FFFFFF",    fontFamily: "Inter",    fontSize: "12px",    fontWeight: 400,    lineHeight: "18px",  },
  
  quicklink: {    color: "#1E293B",    fontFamily: "Inter",    fontSize: "18px",   fontWeight: 700,    lineHeight: "26px",    paddingBottom: "16px",    borderBottom: "1px solid #F1F5F9",  },
  
  links: {    color: "#395169",    fontFamily: "Inter",    fontSize: "16px",    fontWeight: 400,    lineHeight: "24px",    textDecoration: "none",    marginTop: "16px",    cursor: "pointer",  },
  
  optionLabel: {    display: "flex",    justifyContent: "space-between",    paddingBottom: "16px",    borderBottom: "1px solid #F1F5F9",    marginBottom: 16,    cursor: "pointer",  },
 
  numbers: {    color: "#1E293B",    fontFamily: "Inter",    fontSize: "20px",    fontWeight: 700,    lineHeight: "28px",  },
 
  optionLabelText: {    color: "#1E293B",    fontFamily: "Inter",    fontSize: "16px",    fontWeight: 700,   lineHeight: "24px",  },
 
  sliderBox: {    marginTop: 16,    display: "flex",    gap: 26,    justifyContent: "space-between",  },
 
  remainingItemBox: {    display: "flex",    gap: 6,    marginTop: 8,  },
 
  arrowWhite: {    color: "#fff",    height: 12,    marginTop: 2,  },
 
  expandArrow: {    height: 16,    marginTop: 5,  },
 
  editType: {    color: "#1E293B",    fontFamily: "Inter",    fontSize: "16px",    fontWeight: 700,    lineHeight: "24px",  },
 
  editIcon: {    color: "#395169",  },
  
  space: { display: "flex", justifyContent: "space-between", marginTop: 22 },
  
  editHeading: {    color: "#334155",    fontFamily: "Inter",    fontSize: "16px",    fontWeight: 400,    lineHeight: "24px",  },
  
  saveBtn: {
    color: "#FFFFFF",    background: "#395169",    fontFamily: "Inter",   fontSize: "16px",    fontWeight: 700,    lineHeight: "24px",    marginTop: 16,    textTransform: "none" as "none",    padding: "10px 16px",    borderRadius: 8,    cursor: "pointer",
  },
  
  editTextPreferesnce: {    color: "#1E293B",    fontFamily: "Inter",    fontSize: "14px",    fontWeight: 400,    lineHeight: "22px",    marginLeft: 5,    cursor: "pointer",  },
  
  introValueBox: {    color: "#1E293B",    fontFamily: "Inter",    fontSize: "14px",    fontWeight: 500,    lineHeight: "22px",    height: 160,    padding: 16,    border: "0.5px solid #CBD5E1",    borderRadius: 8,    marginTop: 16,  },
  
  addMoreEdu: {
    color: "#395169",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    textAlign: "center" as "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  monthstyle: {
    border: "1px solid #E2E8F0",
    textAlign: "center" as "center",
    padding: 10,
    borderRadius: 8,
    width: 100,
    textTransform: "none" as "none",
  },
  monthYearHeading: {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "26.6px",
    fontWeight: 700,
    lineHeight: "32.47px",
  },
  yearHeading: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 30,
    paddingBottom: 30,
    paddingInline: 23,
  },
  errorMsg: {
    color: "red",
    fontSize: 12,
    fontFamily: "Inter",
  },
  showBoxData: {
    border: "1px solid #E2E8F0",
    borderRadius: "12px",
    padding: "24px",
    marginTop: "30px",
    width: "100%",
  },
  showBoxTtile: {
    color: "1E293B",
    fontFamily: "Inter",
    fontSize: "21px",
    fontWeight: 600,
  },
  organisationTxt: {
    color: "#334155",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "27px",
  },
  repotitle: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "27px",
    marginTop: "15px",
  },
  jobRespon: {
    color: "#334155",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "27px",
    marginTop: "12px",
  },
  expTxt: {
    color: "#395169",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
  },
  addskill: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    marginTop: "15px",
  },
  addbutton: {
    color: "#395169",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    cursor: "pointer",
  },
  skillname: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  skillNameDelete: {
    padding: "3px 16px",
    borderBottom: "1px solid #F1F5F9",
  },
  skillBox: {
    border: "1px solid #E2E8F0",
    borderRadius: "16px",
    marginTop: "9px",
    width: "100%",
  },
  jobprefeBox: {
    borderRadius: "8px",
    border: "1px solid #E2E8F0",
    marginTop: "16px",
  },
  preferTitle: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    borderBottom: "#F1F5F9",
    padding: "15px 16px",
  },
  prefOption: {
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 600,
    color: "#1E293B",
  },
  preTime: {
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 400,
    color: "#1E293B",
  },
  travel: {
    color: "#475569",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    marginTop: "20px",
    marginBottom: "10px",
  },
  avtarColor: {
    width: "32px",
    height: "32px",
    fontWeight: 700,
    fontFamily: "Inter",
    fontSize: "14px",
  },
  preferTime: {
    color: "#395169",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    marginTop: "16px",
    marginLeft: "16px",
  },
  schoolname: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "26px",
  },
  course: {
    color: "#334155",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    marginTop: "20px",
  },
  duration: {
    color: "#475569",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    marginTop: "20px",
  },
  sharewith: {
    border: "1px solid #E2E8F0",
    borderRadius: "8px",
  },
  sharewithHeading: {
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 16px",
    borderBottom: "1px solid #F1F5F9",
  },
  sharewithTxt: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  sharewithOptions: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    padding: "12px 16px",
  },
  uploadBox: {
    padding: "32px 24px",
    borderRadius: "4px",
    border: "1px solid #CBD5E1",
    borderStyle: "dashed",
    textAlign: "center" as "center",
    marginTop: "12px",
    cursor: "pointer",
  },
  filename: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  fileSize: {
    color: "#64748B",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
  },
  jpeg: {
    color: "#64748B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    marginTop: "5px",
  },
  uploadFileTxt: {
    color: "#395169",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  textheading: {
    color: "#64748B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    marginBottom: 6,
    marginTop: "23px",
  },
};
// Customizable Area End