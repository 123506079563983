import React from "react";

// Customizable Area Start
import ForgotPasswordController, { Props } from "./ForgotPasswordController";

import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import { Box, IconButton, InputAdornment, InputLabel, Snackbar, Typography } from "@material-ui/core";
import { backwardIcon } from "../../customform/src/assets";
import { AlertBox, ErrorMessage, InputField, PasswordInputWrapper } from "../../email-account-login/src/EmailAccountLoginBlock.web";
import { CreateAccountButton, PasswordConditionDescTypograpy, PasswordConditionTitleTypograpy, PasswordConditionWrapper } from "../../email-account-registration/src/EmailAccountRegistration.web";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { visibleOff, checkboxConditionFalse, checkboxConditionTrue } from "../../email-account-registration/src/assets";
import { BackIconBox, BackToLoginBox, BackTxtTypography, BackTypography, LoginTypography, MainContainer, MainHeading, SubHeading } from "./ForgotPassword.web";
export const configJSON = require("./config");

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { passwordErrorMsg, confmPasswordErrorMsg, passwordConditions } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <MainContainer>
            <BackIconBox data-test-id="btnBackward" onClick={() => this.goToForgotPassword()}>
                <img style={webStyle.backIcon} src={backwardIcon} alt={configJSON.backIconAlt} />
                <BackTxtTypography style={webStyle.backIconTxt}>{configJSON.backTxt}</BackTxtTypography>
            </BackIconBox>
            <BoxSection>
                <Box style={webStyle.innerContainer}>
                    <MainHeading>
                        {configJSON.setNewTxt} {configJSON.passTxt}{configJSON.wordTxt}
                    </MainHeading>
                    <SubHeading style={{textWrap:"nowrap"}}>
                    {configJSON.subHeadingTxt} {configJSON.passTxt}{configJSON.wordTxt} {configJSON.forTxt} {this.state.f_email}
                    </SubHeading>
                    {this.state.showAlert && <AlertBox>{this.state.alertMsg}</AlertBox>}
                    <Box>
                        <PasswordInputWrapper>
                            <InputsLabel>{configJSON.newTxt} {configJSON.passTxt}{configJSON.wordTxt}</InputsLabel>
                            <InputField
                                data-test-id="txtInputPassword"
                                type={this.state.enableConfirmPasswordField ? "password" : "text"}
                                placeholder={`${configJSON.enterTxt} ${configJSON.newSmallTxt} ${configJSON.passTxt}${configJSON.wordTxt}`}
                                variant='outlined'
                                value={this.state.password}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handlePasswordChange(event.target.value)}
                                error={!!passwordErrorMsg}
                                required
                                InputProps={{
                                    endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        edge="end"
                                        onClick={this.handleClickShowConfirmPassword}
                                        >
                                         {!this.state.enableConfirmPasswordField ? (
                                         <Visibility style={webStyle.eyeIcon} />
                                          ) : (
                                         <VisibilityOff style={webStyle.eyeIcon} />
                                         )}
                                        </IconButton>
                                    </InputAdornment>
                                    ),
                                }}
                            />
                            {passwordErrorMsg && <ErrorMessage>
                                    {passwordErrorMsg}
                            </ErrorMessage>}
                        </PasswordInputWrapper>
                        <PasswordInputWrapper>
                            <InputsLabel>{configJSON.confirmTxt} {configJSON.newSmallTxt} {configJSON.passTxt}{configJSON.wordTxt}</InputsLabel>
                            <InputField
                                data-test-id="txtInputConfirmPassword"
                                type={this.state.enablePasswordField ? "password" : "text"}
                                placeholder={`${configJSON.enterTxt} ${configJSON.newSmallTxt} ${configJSON.passTxt}${configJSON.wordTxt} ${configJSON.againTxt}`}
                                variant="outlined"
                                value={this.state.confmPassword}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleConfirmPasswordChange(event.target.value)}
                                InputProps={{
                                endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    edge="end"
                                    onClick={this.handleClickShowPassword}
                                    >
                                   
                                    {!this.state.enablePasswordField ? (
                                         <Visibility style={webStyle.eyeIcon} />
                                          ) : (
                                         <VisibilityOff style={webStyle.eyeIcon} />
                                         )}
                                    </IconButton>
                                </InputAdornment>
                                ),
                                }}
                                error={!!confmPasswordErrorMsg}
                                required
                            />
                            {confmPasswordErrorMsg && <ErrorMessage>
                                    {confmPasswordErrorMsg}
                            </ErrorMessage>}
                        </PasswordInputWrapper>
                        <PasswordConditionTitleTypograpy>
                            {configJSON.passTxt}{configJSON.wordTxt} {configJSON.mustIncludeTxt}
                        </PasswordConditionTitleTypograpy>
                        <PasswordConditionWrapper>
                            <img style={webStyle.checkboxIcon} src={passwordConditions.isCapital ? checkboxConditionTrue : checkboxConditionFalse} alt={configJSON.checkboxAlt} />
                            <PasswordConditionDescTypograpy>
                            {configJSON.passConditionCapitalTxt}
                            </PasswordConditionDescTypograpy>
                        </PasswordConditionWrapper>

                        <PasswordConditionWrapper >
                            <img style={webStyle.checkboxIcon} src={passwordConditions.isLowercase ? checkboxConditionTrue : checkboxConditionFalse} alt={configJSON.checkboxAlt} />
                            <PasswordConditionDescTypograpy>
                            {configJSON.passConditionLowerTxt}
                            </PasswordConditionDescTypograpy>
                        </PasswordConditionWrapper>

                        <PasswordConditionWrapper >
                            <img style={webStyle.checkboxIcon} src={passwordConditions.isNumber ? checkboxConditionTrue : checkboxConditionFalse} alt={configJSON.checkboxAlt} />
                            <PasswordConditionDescTypograpy>
                            {configJSON.passConditionNumberTxt}
                            </PasswordConditionDescTypograpy>
                        </PasswordConditionWrapper>

                        <PasswordConditionWrapper >
                            <img style={webStyle.checkboxIcon} src={passwordConditions.isLength ? checkboxConditionTrue : checkboxConditionFalse} alt={configJSON.checkboxAlt} />
                            <PasswordConditionDescTypograpy>
                            {configJSON.passConditionLengthTxt}
                            </PasswordConditionDescTypograpy>
                        </PasswordConditionWrapper>

                        <Box>
                            <CreateAccountButton 
                                data-test-id={"btnResetPassword"} 
                                variant="contained" color="primary" fullWidth
                                onClick={() => this.handleResetPassword()}
                                >
                                    {configJSON.resetTxt} {configJSON.passTxt}{configJSON.wordTxt}
                            </CreateAccountButton>
                        </Box>

                        <BackToLoginBox>
                            <BackTypography>{configJSON.backTxt} {configJSON.toTxt}</BackTypography>
                            <LoginTypography>{configJSON.loginTxt}</LoginTypography>
                        </BackToLoginBox>
                    </Box>
                </Box>
            </BoxSection>
        </MainContainer>
        <Snackbar
        data-test-id='btnCloseSnackbar'
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.isSnackbarOpen}
          autoHideDuration={5000}
          onClose={this.closeSnackbar}
        >
          <div style={webStyle.popup as React.CSSProperties}>
            {this.state.redirectingMsg}
          </div>
        </Snackbar>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

const webStyle = {
    backIcon: {
        width: '24px',
        height: '24px',
        marginRight: '6px',
    },
    backIconTxt: {
        color: '#1E293B',
        fontSize: '16px',
        fontWeight: 700,
        fontFamily: 'Inter',
    },
    eyeIcon: {
      height: '24px',
      color: '#94A3B8',
      width: '24px',
    },
    innerContainer: {
      display: 'flex',
      flexDirection: 'column' as 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: 'auto',
      maxWidth: '440px',
      padding: '0 28px',
    },
    checkboxIcon: {
      width: '16px',
      height: '16px',
      marginRight: '8px',
    },
    popup: {
        display: "flex",
        alignItems: "center",
        height: "42px",
        fontFamily:"Inter",
        fontSize: "14px",
        lineHeight: "24px",
        paddingRight: "10px",
        paddingLeft: "10px",
        borderRadius: "8px",
        fontWeight: 400,
        color: "DC2626",
        borderLeft:"4px solid #DC2626",
        backgroundColor: "#FEE2E2", 
    },
};

const BoxSection = styled(Box)({
    width: '384px',
});

const InputsLabel = styled(InputLabel)({
    fontSize: '16px',
    color: '#334155',
    fontFamily: 'Inter',
    lineHeight: '24px',
    'font-weight': 700,
    marginBottom: '6px',
    "@media only screen and (max-width: 414px)": {
      fontSize: '14px',
    },
});

// Customizable Area End