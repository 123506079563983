import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  TextField,
  InputLabel,
  CardMedia,
} from "@material-ui/core";
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
export const configJSON = require("./config");
import { checkboxChecked, checkboxunChecked, pccLogo, visibleOff, wholeBackgroundImage, wholeBackgroundImageforMobile } from "./assets";
import Splashscreen from "../../splashscreen/src/Splashscreen.web";
import { VisibilityOff } from "@material-ui/icons";
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 handleKeyPress(event:React.KeyboardEvent<HTMLElement>){
  if (event.key === "Enter") {
    this.handleEmailLogin(); 
  }
 }
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        {
          this.state.isSplashVisited  ? <Splashscreen isSplashVisited={this.setVisited} navigation='navigation' id='SplashScreen' /> : 
          <MainContainerBox>
            <Box style={webStyle.innerContainer}>
              <WebLogoImage data-test-id='gotToLandingPage' image={pccLogo} onClick={this.gotToLandingPage} />
              <WelcomeTextTypography>
                {configJSON.welcomeBackTxt}
              </WelcomeTextTypography>
              <EnterEmailAndPassTxtTypography>
                {configJSON.enterEmailAndPasswordTxt} {configJSON.passTxt}{configJSON.wordTxt} {configJSON.toContinueTxt}
              </EnterEmailAndPassTxtTypography>
              {this.state.showAlert && <AlertBox>{this.state.alertMsg}</AlertBox>}
              <Box style={{
                "maxWidth": "384px",
                "width": "90%",
              }}>
                <EmailInputWrapper>
                  <InputsLabel>{configJSON.emailTxt}</InputsLabel>
                  <InputField
                    data-test-id="txtInputEmail"
                    placeholder={configJSON.emailPlaceholderTxt}
                    value={this.state.email}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleEmailChange(event.target.value)}
                    variant='outlined'
                    onKeyPress={(event) => {
                      this.handleKeyPress(event)
                    }}
                    error={!!this.state.emailErrorMsg}
                    required
                  />
                  {this.state.emailErrorMsg && <ErrorMessage>
                        {this.state.emailErrorMsg}
                </ErrorMessage>}
                </EmailInputWrapper>
                <PasswordInputWrapper>
                  <InputsLabel>{configJSON.passTxt}{configJSON.wordTxt}</InputsLabel>
                  <InputField
                    data-test-id="txtInputPassword"
                    type={this.state.enablePasswordField ? "password" : "text"}
                    placeholder={`${configJSON.enterTxt} ${configJSON.passTxt}${configJSON.wordTxt}`}
                    variant="outlined"
                    value={this.state.password}
                    onKeyPress={(event) => {this.handleKeyPress(event)}}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handlePasswordChange(event.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            edge="end"
                          >
                            {!this.state.enablePasswordField ? (
                          <Visibility style={webStyle.eyeIcon} />
                        ) : (
                          <VisibilityOff style={webStyle.eyeIcon} />
                        )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={!!this.state.passwordErrorMsg}
                    required
                  />
                </PasswordInputWrapper>
                {this.state.passwordErrorMsg && <ErrorMessage>
                        {this.state.passwordErrorMsg}
                </ErrorMessage>}
                <RememberForgetPassWrapper>
                  <RememberMeWrapper>
                    {
                      this.state.checkedRememberMe ? 
                      <img data-test-id={"btnRememberMe"} onClick={() =>
                        this.setRememberMe(!this.state.checkedRememberMe)
                      }
                      style={webStyle.rememberCheckBox}
                       src={checkboxChecked} alt="checkboxChecked" /> 
                      : <img data-test-id={"btnRememberMe"} onClick={() =>
                        this.setRememberMe(!this.state.checkedRememberMe)
                      }
                      style={webStyle.rememberCheckBox}
                      src={checkboxunChecked} alt="checkboxunChecked" />
                    }
                    {configJSON.rememberMeTxt}
                  </RememberMeWrapper>
                  <Box
                    data-test-id={"btnForgotPassword"}
                    style={webStyle.forgetPasswordBox}
                    onClick={() => this.goToForgotPassword()}
                  >
                    {configJSON.forgetPasswordTxt} {configJSON.passTxt}{configJSON.wordTxt}{configJSON.questionMarkTxt}
                  </Box>
                </RememberForgetPassWrapper>

                <Box>
                  <LoginButton
                    data-test-id={"btnEmailLogIn"}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => this.handleEmailLogin()}
                  >
                    {configJSON.loginText}
                  </LoginButton>
                </Box>
                <DontHaveAccountWrapper>
                  {configJSON.dontHaveAccText}
                  <SignUpTextTypography
                    data-test-id="btnSocialLogin"
                    onClick={() => this.goToSignupPage()}
                  >
                    {configJSON.signupText}
                  </SignUpTextTypography>
                </DontHaveAccountWrapper>
              </Box>
            </Box>
          </MainContainerBox>
        }
      </ThemeProvider>
       // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  innerContainer: {
    width:"100%",
    height: 'auto',
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  eyeIcon: {
    color: '#94A3B8',
    width: '24px',
    height: '24px',
  },
  rememberCheckBox: {
    padding: '0px',
    marginRight: '8px',
    color: '#395169',
    width: '21.33px',
    height: '20px',
    cursor: 'pointer',
  },
  forgetPasswordBox: {
    fontSize: '14px',
    'font-weight': '700',
    color: '#395169',
    fontFamily: 'Inter',
    cursor: 'pointer',
    'text-transform': 'capitalize',
  },
};

export const WebLogoImage = styled(CardMedia)({
  height: '90px',
  width: '260.71px',
  display: 'block',
  margin: 'auto',
  cursor:"pointer",
  "@media only screen and (max-width: 414px)": {
    marginBottom: '48px',
  },
});

const MainContainerBox = styled(Box)({
  display: 'flex',
  padding: '130px 0 162px 0',
  justifyContent: 'center',
  background: `url(${wholeBackgroundImage})`,
  height:'100vh',
  alignItems: 'center',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  "@media only screen and (max-width: 430px)": {
    display:'unset'
  },
  "@media only screen and (max-width: 414px)": {
    padding: '85px 0 192px 0',
    background: `url(${wholeBackgroundImageforMobile})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
});

const WelcomeTextTypography = styled(Typography)({
  maxWidth: '384px',
  width:"100%",
  fontSize: '30px',
  color: '#1E293B',
  marginTop: '48px',
  lineHeight: '40px',
  fontFamily: 'Inter',
  textAlign: 'left',
  'font-weight': 700,
  "@media only screen and (max-width: 414px)": {
    display: 'none',
  },
});

export const EnterEmailAndPassTxtTypography = styled(Typography)({
  maxWidth: '384px',
  width:"100%",
  fontSize: '16px',
  'font-weight': '400',
  color: '#334155',
  lineHeight: '24px',
  marginTop: '8px',
  fontFamily: 'Inter',
  textAlign: 'left',
  marginBottom: '32px',
  "@media only screen and (max-width: 414px)": {
    display: 'none',
  },
});

export const EmailInputWrapper = styled(Box)({
  width: '100%',
  margin: 'auto',
  "@media only screen and (max-width: 414px)": {
  },
});

export const PasswordInputWrapper = styled(Box)({
  marginTop: '16px',
  width: '100%',
  margin: 'auto',
  "@media only screen and (max-width: 414px)": {
  },
});

export const AlertBox = styled(Box)({
  maxWidth:'384px',
  width:"100%",
  padding: '12px 0 12px 16px',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '18px',
  backgroundColor: "#FEE2E2",
  color:"#DC2626",
  borderLeft : '4px solid #DC2626',
  marginBottom: '32px',
  boxSizing:'border-box',
  borderRadius: '8px',
  "@media only screen and (max-width: 414px)": {
    width:'327px',
    marginTop: '0',
    marginBottom: '16px',
    padding: '11px 0 11px 16px',
  },
});

export const InputsLabel = styled(InputLabel)({
  color: '#334155',
  fontSize: '16px',
  'font-weight': 700,
  fontFamily: 'Inter',
  marginBottom: '6px',
  lineHeight: '24px',
  textTransform: 'capitalize',
  "@media only screen and (max-width: 414px)": {
    fontSize: '14px',
  },
});

export const InputField = styled(TextField)({
  marginBottom: "0px",
  width:"100%",
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#FFFFFF",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      borderColor: "#FFFFFF",
    },
  },
  "& .MuiInputBase-root":{
    backgroundColor: "#FFFFFF",
    height: '56px',
    margin: 'auto',
    width:'100%',
    borderRadius: '8px',
    fontSize: '16px',
    fontFamily: 'Inter',
    "@media only screen and (max-width: 414px)": {
      fontSize: '14px',
    },
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#FFFFFF",
    borderWidth: "1px",
    borderRadius: "8px"
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px 16px 10px 16px",
    color: '#1E293B',
    fontFamily: 'Inter',
    "&::placeholder": {
      color: '#94A3B8',
      fontSize: "16px",
      fontFamily: 'Inter',
      "@media only screen and (max-width: 414px)": {
        fontSize: '14px',
      },
    },
    "&:not(:placeholder-shown)": {
      color: '#1E293B',
      fontFamily: 'Inter',
    },
  },
  "& .MuiInputBase-input[type='date']": {
    lineHeight: "19.2px",
    textTransform:'uppercase',
    color:'red'
  }
  , "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F87171"
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#DC2626",
    fontSize: "12px",
    fontFamily: "Lato",
    fontWeight: 400,
    lineHeight: "18px"
  },
  "& .MuiFormHelperText-contained": {
    marginLeft: "0px",
    marginRight: "0px"
  },
  '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':{
    border:"1px solid #FFFFFF",
  }
});

export const ErrorMessage = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 400,
  color: '#DC2626',
  marginTop: '4px',
})

const RememberForgetPassWrapper = styled(Box)({
  margin: '12px auto auto auto',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  lineHeight: '22px',
  "@media only screen and (max-width: 414px)": {
  },
});

const RememberMeWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  color: '#334155',
  fontSize: '16px',
  'font-weight': '400',
  borderRadius: '6px',
  fontFamily: 'Inter',
  "@media only screen and (max-width: 414px)": {
    fontSize: '14px',
  },
});

const LoginButton = styled(Button)({
  backgroundColor: '#395169',
  height: '56px',
  borderRadius: '8px',
  marginTop: '32px',
  color: '#FFFFFF',
  fontSize: '16px',
  fontFamily: 'Inter',
  boxShadow: 'none',
  textTransform: 'none',
  'font-weight': '700',
  '&:hover': {
    backgroundColor: '#395169',
    boxShadow: 'none',
  },
  "@media only screen and (max-width: 414px)": {
  },
});

const DontHaveAccountWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginTop: '32px',
  fontSize: '16px',
  color: '#334155',
  fontFamily: 'Inter',
  'font-weight': 400,
  "@media only screen and (max-width: 414px)": {
    justifyContent: 'center',
    fontSize: '14px',
  },
});

const SignUpTextTypography = styled(Typography)({
  color: '#395169',
  marginLeft: '8px',
  fontSize: '16px',
  'font-weight': 700,
  cursor: 'pointer',
  fontFamily: 'Inter',
  "@media only screen and (max-width: 414px)": {
    fontSize: '14px',
  },
});
// Customizable Area End