import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Typography,
  Button,
  styled, Grid, TextField, InputAdornment,
  MenuItem,
  Menu,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
import Navbar from "../../../components/src/Navbar.web"
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Footer from "../../../components/src/Footer.web";
import { dummy, noData, sendbtn, logo } from "./assets";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { ArrowBackIos } from "@material-ui/icons";
import Loader from "../../../components/src/Loader.web";
import CloseIcon from "@material-ui/icons/Close";
import { pdfIcon } from "../../../blocks/chat/src/assets";
import { doc } from "../../../blocks/joblisting/src/assets";
// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  styleCondition = (index: number) => {
    const backgroundColor = this.state.selectedContactId == index ? '#F1F5F7' : '#fff'
    return { backgroundColor }
  }
  styledUnreadCount = (item: number) => {
    const fontWeight = item != 0 ? 600 : 400
    const color = item != 0 ? '#1E293B' : '#64748B'
    const display = item == 0 ? 'none' : ''
    const background = item == 0 ? '#fff' : '#F1F5F7'
    return { fontWeight, color, display, background }
  }
  styledMsgType = (type: string) => {
    const justifycontent = type !== 'receiver' ? 'flex-end' : 'flex-start'
    const right = type !== 'receiver' ? 3 : ''
    const left = type === 'receiver' ? 1 : ''
    return { justifycontent, right, left }
  }
  formatTime = (timestamp: string | number | Date) => {
    const inputDate = new Date(timestamp) as any;
    const currentDate = new Date() as any;
    const diffInMilliseconds = currentDate - inputDate;
    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
    const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

    let formattedTime;

    if (diffInMinutes < 60) {
      formattedTime = `${diffInMinutes} min${diffInMinutes > 1 ? 's' : ''}`;
    } else if (diffInHours < 24) {
      formattedTime = `${diffInHours} h${diffInHours > 1 ? 's' : ''}`;
    } else if (diffInDays === 1) {
      formattedTime = 'Yesterday';
    } else {
      const options = { month: 'short', day: 'numeric' };
      formattedTime = inputDate.toLocaleDateString('en-US', options);
    }

    return formattedTime;
  }

  groupMessagesByDate = (messages: any) => {
    const groupedMessages: any = {};

    messages?.forEach((message: any) => {
      const [day, month, year] = message?.created_at.split('/').map(Number);
      const date = new Date(year, month - 1, day);

      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);

      let dateLabel;
      if (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      ) {
        dateLabel = 'Today';
      } else if (
        date.getDate() === yesterday.getDate() &&
        date.getMonth() === yesterday.getMonth() &&
        date.getFullYear() === yesterday.getFullYear()
      ) {
        dateLabel = 'Yesterday';
      } else {
        dateLabel = date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
      }

      if (!groupedMessages[dateLabel]) {
        groupedMessages[dateLabel] = [];
      }

      groupedMessages[dateLabel].push(message);
    });

    return groupedMessages;
  };
  renderCIcons(icon:any) {
    const wordFileExtensions = [".doc", ".docm", ".docx", ".dot", ".dotx", ".dotm"];
    if (icon?.includes('.pdf')) {
      return (
        <>
          <img src={pdfIcon} style={{ width: '40px', height: '40px' }} alt="PDF Icon" />
        </>
      );
    }else if(wordFileExtensions.some(extension => icon?.includes(extension))){
      return <img src={doc} style={{ width: '40px', height: '40px' }} alt="PDF Icon" />
    } else {
      return (
        <img src={icon} alt="Uploaded file" style={{ width: '40px', height: '40px' }} />
      );
    }
  }
  adminTeacherText = () => {
    return (
      <>
        {this.state.selectedContact?.role.id == 1 ? 'School Admin' : 'Substitute Teacher'}
      </>
    )
  }

  getCursor(){
      return this.state.role == '2' ?"pointer" : "default"
  }

  getMultiMsg(message:any , align:string){
    return (message?.files?.length > 0 && message.body !== '') &&
      <div 
        style={
          {
            width: "100%",
            textAlign: align as any,
            borderTop: "1px solid #F1F5F7",
            marginTop: "8px",
          }
        }> 
       
          <span style={webStyles.message as any}>{message.body}</span>
      </div>
  }
  
  
  renderMessage = (message: any) => {
    const cursor = this.getCursor() 
    const { menuItems } = this.state
    const selectedJob = menuItems.find((e) => e.value === message.job_id)
    const align = message?.type !== 'receiver' ? 'end' : 'start'
    if (message.job_offer) {
      const roleId = this.state.selectedContact?.role.id;
      const isRoleAdmin = roleId === 1;

      return (
        <Box style={webStyles.offerContainer}>
          <ButtonContainer >
            <Box style={{ width: "100px", height: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img src={logo} alt="" style={{ width: "50px", height: "50px" }} />
            </Box>
            <span style={{ ...webStyles.jobTitleChat, whiteSpace: "nowrap" }}>{message?.job_title}</span>
            {!message.decline && (
              <button
                style={{ ...webStyles.customButtonGreen, maxWidth: "150px", whiteSpace: "nowrap" , cursor: cursor}}
                onClick={() => this.handleAcceptJob(message.job_id , message.job_title)}
                data-test-id={`acceptButtonTestId${roleId}`}
                disabled={!isRoleAdmin || message.accept}
              >
                {message.accept ? "Accepted!" : "Accept"}
              </button>
            )}
            {!message.accept && (
              <button
                style={{ ...webStyles.customButtonBlue, maxWidth: "150px", whiteSpace: "nowrap" ,cursor: cursor}}
                onClick={() => this.handleDeclainJob(message.job_id , message.job_title)}
                data-test-id="declainButtonTest"
                disabled={!isRoleAdmin || message.decline}
              >
                {message.decline ? "Declined" : "Decline"}
              </button>
            )}
          </ButtonContainer>
        </Box>
      );
    }
      if (message?.files?.length>0) {
      const fileName = message.files[0].filename
      const fileSize = message.files[0].size
      const fileUrl= message.files[0].url
      const openPDF = () => {
        window.open(fileUrl, "_blank")
      };

      return (
        <div style={{display:"flex" ,flexDirection:"column" , alignItems:align}}>
        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', gap: '10px' }} data-test-id="openPDF" onClick={openPDF}>
          {this.renderCIcons(fileUrl)}

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={webStyles.fileName}>{fileName}</span>
            <span style={{ fontSize: '12px', color: '#999999' }}>{fileSize}</span>
          </div>
        </div>
        {this.getMultiMsg(message ,align)}
        </div>
      );
    }
    if(message.body){
      return <span style={webStyles.message as any}>{message.body}</span>;
    }
  };


  isMessageAvailable = () => {
    const type = this.state.selectedContact?.role.id == 2 ? "sender" : "receiver"
    const filteredMessages = this.state.messages?.filter((message:any, index:any, arr:any) => {
      if (!message.job_id || message.type !== type) {
        return true;
      }
    
      const laterMessage = arr.slice(index + 1).find(
        (msg:any) => msg.job_id === message.job_id
      );
    
      return !laterMessage;
    });
    
    const groupedMessages = this.groupMessagesByDate(filteredMessages)
    const { selectedContact } = this.state
    return (
      <>
        {this.state.messagesAvailable ? (
          <Container>
            <Box display="flex" flexDirection="column" alignItems="center" marginTop={2}>
              {Object.keys(groupedMessages).map((dateLabel) => (
                <React.Fragment key={dateLabel}>
                  <Typography variant="h6" style={{
                    marginTop: '16px', marginBottom: '8px', fontFamily: 'Inter',
                    padding: '4px 12px',
                    background: 'white',
                    border: '1px solid #E2E8F0',
                    borderRadius: '4px',
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '18px',
                  }}>{dateLabel}</Typography>
                  {groupedMessages[dateLabel].map((message: any) => (
                    <Box
                      key={message.message_sid}
                      display="flex"
                      justifyContent={this.styledMsgType(message.type).justifycontent}
                      width="100%"
                      marginY={1}
                    >
                      <Box
                        style={{
                          padding: '10px',
                          maxWidth: '70%',
                          backgroundColor: '#fff',
                          position: 'relative',
                          borderRadius: '0px 8px 8px 8px',
                          marginTop: '10px'
                        }}
                      >
                        {this.renderMessage(message)}
                        <Typography
                          variant="caption"
                          display="block"
                          align={'left'}
                          style={{
                            position: 'absolute',
                            right: this.styledMsgType(message.type ).right ,
                            left: this.styledMsgType(message.type ).left,
                            color: '#64748B',
                            fontFamily: 'Inter',
                            fontSize: '10px',
                            fontWeight: 400,
                            width: '50px',
                            lineHeight: '15px',
                            bottom: -16
                          }}
                        >
                          {message.date_updated}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </React.Fragment>
              ))}
            </Box>
          </Container>
        )
          : (
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'inherit' }}>
              <Typography style={webStyles.startchat}>Start chatting with {selectedContact ? `${selectedContact.first_name} ${selectedContact.last_name}` : 'a contact'}</Typography>
            </Box>
          )}</>
    )
  }
  chatList = () => {
    const { conversationList } = this.state
    return (
      <>
        {conversationList && conversationList.map((item, index) => {
          const { first_name, last_name, email, organization, role, profile_image } = item.other_party_details.data.attributes;
          return <ChatContact key={index} data-test-id="handleContactClick"
            onClick={() => this.handleContactClick({
              first_name: first_name, last_name: last_name, conversation_sid: item.conversation_sid, email: email, receiver_unread_count: item.receiver_unread_count,
              organization: organization, role: role, profile_image: profile_image
            }, index)}
            style={{ backgroundColor: this.styleCondition(index).backgroundColor }}
          >
            <Box style={{display:"flex", gap:"15px"}}>
              <Box>
                <Box style={webStyles.profilePic}>
                  {!profile_image ?
                    <img src={dummy} style={webStyles.image as React.CSSProperties} /> :
                    <img src={profile_image.url} style={webStyles.image as React.CSSProperties} />
                  }
                </Box>
              </Box>
              <Box style={{display:"flex" , justifyContent:"space-between" , width:"100%"}}>
                <Box>
                  <Typography
                    style={{ ...webStyles.name, fontWeight: this.styledUnreadCount(item.receiver_unread_count).fontWeight }}
                  >
                    {first_name} {last_name}</Typography>
                  <Typography
                    style={{ ...webStyles.message, whiteSpace: 'nowrap', 
                      overflow: 'hidden',   
                      textOverflow: 'ellipsis',
                      maxWidth: "150px"
                      ,color: this.styledUnreadCount(item.receiver_unread_count).color } as any}
                  >
                    {this.shortString(item.last_message)}
                  </Typography>
                </Box>
                <Box style={{ position: 'relative', marginLeft: 30 }}>
                  <Typography style={webStyles.time}>{this.formatTime(item.last_message_time)}</Typography>
                  <Typography style={{
                    ...webStyles.unreadCount as React.CSSProperties, display: this.styledUnreadCount(item.receiver_unread_count).display,
                    background: this.styledUnreadCount(item.receiver_unread_count).background
                  }}>
                    {item.receiver_unread_count}</Typography>
                </Box>
              </Box>
            </Box>
          </ChatContact>
        })}</>
    )
  }
  renderico = (fileUrl: string, fileName: string) => {
    const wordFileExtensions = [".doc", ".docm", ".docx", ".dot", ".dotx", ".dotm"];
    if (fileName?.includes(".pdf")) {
      return <img src={pdfIcon} style={{ width: "40px", height: "40px" }} alt="PDF Icon" />;
    } else if (wordFileExtensions.some((extension) => fileName?.includes(extension))) {
      return <img src={doc} style={{ width: "40px", height: "40px" }} alt="DOC Icon" />;
    } else {
      return <img src={fileUrl} alt="Uploaded file" style={{ width: "40px", height: "40px", borderRadius: "10px" , objectFit:"contain" }} />;
    }
  };
  inputField = () => {
    const { typedMessage , selectedFile } = this.state;
    const fileUrl = selectedFile ? URL.createObjectURL(selectedFile) : "";
    const fileName = selectedFile?.name;
    return (
      <>
      <InputField variant="outlined" fullWidth placeholder="Type here..." value={typedMessage} data-test-id="enteredtext"
        onKeyPress={(event) => this.handleKeyDown(event)}
        onChange={(event) => this.enteredText(event.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {selectedFile && 
              <CustomBox>
                <Box style={{ position: "relative" }}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "15px",
                      width: "15px",
                      position: "absolute",
                      top: "-10px",
                      right: "0",
                      background: "black",
                      color: "white",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                    data-test-id="deleteFile"
                    onClick={()=>this.deleteFile()}
                  >
                    <CloseIcon style={{ fontSize: "15px" }} />
                  </Box>
                  {this.renderico(fileUrl, fileName||'')}
                </Box>
              </CustomBox>}
              <AttachFileIcon style={{ color: '#475569', cursor: 'pointer' }} data-test-id="handleFileInputClick" onClick={this.handleFileInputClick} />
              <input
                type="file"
                data-test-id='handleFileChange'
                accept=".pdf, .jpeg, .png, .jpg, .txt, .docx, .doc"
                ref={(input) => (this.fileInput = input)}
                style={{ display: 'none' }}
                onChange={this.handleFileChange} 
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Box style={webStyles.sendbtn}>
                <img src={sendbtn} data-test-id="sendMessageClick" onClick={this.sendMessageClick} />
              </Box>
            </InputAdornment>
          )
        }}
      />
      {this.state.errorMessage && <p style={{ color: '#DC2626',fontFamily: 'Inter',fontSize: '13px',marginTop: 5,marginLeft: 29}}>{this.state.errorMessage}</p>}
      </>
    )
  }

  getImage (){
    return this.state.selectedContact?.profile_image.url ?
      <img src={this.state.selectedContact?.profile_image?.url} style={webStyles.image} /> :
      <img src={dummy} style={webStyles.image} /> 
  }

  renderChatScreen =(renderButton:()=>void)=>{
    const { isMobileView, selectedContact, openNoChats} = this.state;
    const shouldShowNoData = this.state.conversationList?.length === 0;
    const shouldRenderButton1 = !this.state.isOffered && this.state.selectedContact?.role.id !== 1 && this.state.role == '1' ;
    const shouldShowChatList = !isMobileView && !openNoChats;
    if (this.state.loader) {
      return <Loader loading={this.state.loader}/>
    }

    return(
      <>
        {shouldShowNoData?
          <NoDataBox>
            <img src={noData} />
            <Typography style={webStyles.noData}>Nothing to show here yet!</Typography>
          </NoDataBox>
          :
          shouldShowChatList &&
            <Grid container spacing={2}>
              <Grid item xs={12} sm={5} md={5}>
                <ScrollBox>
                  <ChatList>
                    {this.chatList()}
                  </ChatList>
                </ScrollBox>
              </Grid>
              <Grid item xs={12} sm={7} md={7}>
                {selectedContact &&
                  <RightBox>
                    {selectedContact ? (
                      <Grid container style={{ padding: '16px 24px' }} spacing={2}>
                        <Grid item sm={2}>
                          <Box style={webStyles.image}>
                            {this.getImage()}
                          </Box>
                        </Grid>
                        <Grid item sm={7}>
                          <Typography style={webStyles.contactName}>{selectedContact.first_name} {selectedContact.last_name}</Typography>
                          <Typography style={webStyles.description}>
                            {this.adminTeacherText()} . {selectedContact.organization}
                          </Typography>
                        </Grid>
                        <Grid item sm={3} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          {shouldRenderButton1 &&
                            <>
                              {renderButton()}
                            </>
                          }
                          {this.state.isOffered && this.state.selectedContact?.role.id !== 1 && <button style={{ ...webStyles.customButtonGreen, maxWidth: "150px", textWrap: "nowrap" }} data-test-id="offeredButtontestId">
                            Job Offered!
                          </button>}
                        </Grid>
                      </Grid>
                    ) : (
                      <></>
                    )}
                    <ScrollBox>
                      <div ref={this.scrollEnd} style={webStyles.messageBox}>
                        {this.isMessageAvailable()}
                      </div>
                    </ScrollBox>
                    <Box style={{ marginTop: 16  , position:"relative"}}>
                      {this.inputField()}
                    </Box>
                  </RightBox>
                }
              </Grid>
            </Grid>
          }
        
      </>
    )
  }

  getDisableCursor(){
    return !this.state.disable ? 'pointer' : 'default'
  }

  renderFooter (){
    return ((this.state.isMobileView && !this.state.selectedContact) || !this.state.isMobileView) &&  <Footer navigation={this.props.navigation} id={""} NaviagationPage={"Chat"} />
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isMobileView, selectedContact, openNoChats, isOffered, chatAnchorEl, menuItems } = this.state;

    const getCursor = this.getDisableCursor()
    const open = Boolean(chatAnchorEl);
    const shouldRenderButton = isMobileView && !isOffered && this.state.selectedContact?.role.id !== 1 && this.state.role == '1';
    const shouldRenderButton1 = !this.state.isOffered && this.state.selectedContact?.role.id !== 1 && this.state.role == '1' ;

    const renderButton = () => (
      <button style={{ ...webStyles.customButtonBlue, maxWidth: "150px", textWrap: "nowrap" }}>
        <Box style={{ display: "flex", marginTop: 4 ,justifyContent:"space-between" , width:"100%"}} onClick={this.handleClick} data-test-id="offerButtonTestId">
          <Typography style={webStyles.offerDropdown}>Offer Job</Typography>
          <ArrowDropDownIcon />
        </Box>
        <Dropdown
          id="basic-menu"
          anchorEl={chatAnchorEl}
          open={open}
          onClose={this.handleClose}
        >
          {menuItems?.map((item: any, index) => (
            <MenuItem
              key={index}
              data-test-id="menuTestId"
              value={item.value}
              style={{cursor:getCursor}}
              disabled={this.state.disable}
              onClick={() => this.handleMenuItemClick(item.title, item.value)}
            >
              {item?.title}
            </MenuItem>
          ))}
        </Dropdown>
      </button>
    );

    return (
      <Box>
        <CustomNavbar>
          <Navbar navigation={this.props.navigation} id={""} NaviagationPage={"Chat"} />
        </CustomNavbar>
        <MobileHeader >
          {selectedContact ? (
            <>
            <div style={{display:"flex" , alignItems:"center"}}>
              <ArrowBackIos style={{ color: '#fff', marginTop: '11px', marginRight: '10px' }} onClick={this.navigateBackToList} />
              <div style={{ display: 'block' }}>
                <Typography style={webStyles.mobilename}>{selectedContact.first_name} {selectedContact.last_name}</Typography>
                <NoFoundTypo > {this.adminTeacherText()} . {selectedContact.organization}</NoFoundTypo>
              </div>
            </div>
              {shouldRenderButton && (
                <>
                  {renderButton()}
                </>
              )
              }
              {isMobileView && this.state.isOffered && this.state.selectedContact?.role.id !== 1 && <button style={{ ...webStyles.customButtonGreen, maxWidth: "150px", textWrap: "nowrap" }} data-test-id="offeredButtontestId">
                Job Offered!
              </button>}
            </>
          ) :
            <>
              <ArrowBackIos style={{ color: '#fff' }} onClick={this.navigateToHome} />
              <Typography style={webStyles.mobileHeading}>Chat</Typography>
            </>
          }
        </MobileHeader>
        <MainBox style={{minHeight:"50vh" , position:"relative"}}>
          <Navigation>
            <Typography data-test-id="navigateToHome" onClick={this.navigateToHome} style={webStyles.home}>Home</Typography>
            <ArrowForwardIosIcon style={webStyles.forwardArrow} />
            <Typography style={webStyles.joblisting}>Chat</Typography>
          </Navigation>
          <ChatHeading>Chats</ChatHeading>
          {this.renderChatScreen(renderButton)}

          {
            isMobileView && !openNoChats && (
              <>
                {selectedContact ? (
                  <Grid item xs={12} sm={12} md={12}>
                    <RightBox>
                      <ScrollBox>
                        <div ref={this.scrollEnd} style={webStyles.msgBox}>
                          {this.isMessageAvailable()}
                        </div>
                      </ScrollBox>
                      <Box style={{ marginTop: 16 }}>
                        {this.inputField()}
                      </Box>
                    </RightBox>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={12} md={12}>
                    <ScrollBox>
                      <ChatList>
                        {this.chatList()}
                      </ChatList>
                    </ScrollBox>
                  </Grid>
                )
                }</>
            )
          }

        </MainBox>
        {this.renderFooter()} 
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const role = localStorage.getItem("role");
const width = role == '1' ? 850 : 571; 

const CustomNavbar = styled(Box)({
  [`@media only screen and (max-width: ${width}px)`]: {
    display: 'none',
  },
});
const NoDataBox = styled(Box)({
  background: "#fff",
  textAlign: 'center',
  paddingTop: 114,
  paddingBottom: 160,
  borderRadius: 16
})
const MainBox = styled(Box)({
  padding: "90px 222px",
  background: "#F8F8F8",
  position: 'relative',
  justifyContent: "space-between",
  boxShadow: "0px 2px 8px 0px #00000014",
  "@media only screen and (max-width: 1255px)": {
    padding: "90px 100px"
  },
  "@media only screen and (max-width: 890px)": {
    padding: "90px 58px"
  },
  "@media only screen and (max-width: 774px)": {
    padding: "90px 35px"
  },
  "@media only screen and (max-width: 571px)": {
    padding: "0px"
  },
})
const InputField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "40px"
  }
})
const Navigation = styled(Box)({
  display: "flex",
  gap: "10px",
  position: "absolute" as "absolute",
  top: 32,
  left: 114,
  [`@media only screen and (max-width: ${width}px)`]: {
    display: 'none',
  }
})
const ChatHeading = styled(Typography)({
  color: '#1E293B',
  fontFamily: "Inter",
  fontSize: '30px',
  fontWeight: 700,
  lineHeight: '40px',
  marginBottom: 24,
  [`@media only screen and (max-width: ${width}px)`]: {
    display: 'none',
  }
})
const ChatList = styled(Box)({
  height: '100%',
  overflowY: 'scroll',
  background: "#fff",
  padding: '16px 8px',
  borderRadius: 16
})
const ChatContact = styled(Box)({
  padding: '16px 20px',
  borderBottom: '1px solid #F1F5F7',
  cursor: 'pointer'
})
const ScrollBox = styled(Box)({
  "& div::-webkit-scrollbar": {
    width: "7px",
    background: "#E2E8F0",
    paddingLeft: "-20px",
    borderRadius: '20px'
  },
  "& div::-webkit-scrollbar-track": {
    border: '1px solid #E2E8F0',
    "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    borderRadius: "10px",
    background: "#E2E8F0"
  },
  "& div::-webkit-scrollbar-thumb": {
    backgroundColor: "#94A3B8",
    borderRadius: "10px"
  }
})
const ResButton = styled(Button)({
  backgroundColor: '#3f5060',
  color: '#fff',
  padding: "10px 10px",
  borderRadius: '7px',
  width: "100%",
  fontSize: "12px",
  fontWeight: 500,
  border: "none",
  outline: "none",
  cursor: "pointer",
   maxWidth: "150px", textWrap: "nowrap"
})
const NoFoundTypo = styled(Typography)({
  color: '#fff',
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
    [`@media only screen and (max-width: 400px)`]: {
      fontSize: '13px',
    }
})
const RightBox = styled(Box)({
  background: '#fff',
  borderRadius: 16,
  height: `auto`,
  padding: '16px 12px 20px 12px',
  '@media only screen and (min-width: 571px)': {
    height:'100%'
  } 
})
const ButtonContainer = styled(Box)({
  width: "100%",
    background: "#fff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
    padding: "10px 0px",
    height: "70px",
    borderRadius: "7px",
    maxWidth: "400px",
    '@media only screen and (max-width: 380px)':{
      marginRight:"50px"
    }
})
const CustomBox = styled(Box)({
  maxWidth: "50px",
  width: "100%",
  height: "50px",
  position: "absolute",
  bottom: "45px",
  left: "27px",
  borderRadius: "10px",
});

const MobileHeader = styled(Box)({
  display: 'none',
  [`@media only screen and (max-width: ${width}px)`]: {
    background: '#395169',
    display: 'flex',
    padding: 16,
    position: 'relative',
    justifyContent: "space-between", alignItems: "center"
    // height:'26px'
  }
})

const MessageBox = styled(Box)({
  background: '#FAFAF9',
  borderRadius: '12px',
  height: 576,
  overflowY: 'scroll' as 'scroll',
  [`@media only screen and (max-width: ${width}px)`]: {
    height: '637px'
  }
})
const Dropdown = styled(Menu)({
  "& .MuiPopover-paper": {
    marginTop: "50px",
    border: '1px solid #E2E8F0',
    borderRadius: "8px"
  },
  "& .MuiPaper-elevation8": {
    boxShadow: "0px 4px 8px 0px #00000008",
    width: 180
  }
})
const webStyles = {
  fileName: {
    color: '#0F172A',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    whiteSpace: 'nowrap', 
    overflow: 'hidden',   
    textOverflow: 'ellipsis',
    maxWidth: "150px"
  },
  fileSize: {
    color: '#64748B',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
  },
  joblisting: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  home: {
    color: "#475569",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    cursor:"pointer"
  },
  forwardArrow: {
    color: "#395169",
    height: "15px",
    marginTop: 4
  },
  image: {
    width: "44px",
    borderRadius: '50%',
    height: '44px'
  },
  unreadCount: {
    color: 'Brand/1',
    width: 20,
    height: 20,
    borderRadius: '50%',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '18px',
    textAlign: 'center',
    position: 'absolute',
    top: 25,
    right: 7
  },
  nameMessage: {
    left: 59,
    position: 'absolute',
    top: 0,
  },
  message: {
    color: '#1E293B',
    fontFamily: 'Inter' as 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
   textWrap:"wrap",
   wordBreak : "break-word"
  },
  name: {
    color: '#0F172A',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  time: {
    color: '#64748B',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '22px',
    position: 'absolute' as 'absolute',
    right: 10,
    width: 43,
    textAlign: 'end' as 'end'
  },
  profilePic: {
    borderRadius: "50%"
  },
  contactName: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '26px',
  },
  description: {
    color: '#64748B',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  messageBox: {
    background: '#FAFAF9',
    borderRadius: '12px',
    height: ` calc(100vh - 200px)`,
    overflowY: 'scroll' as 'scroll'
  },

  msgBox: {
    background: '#FAFAF9',
    borderRadius: '12px',
    height: ` calc(100vh - 200px)`,
    overflowY: 'scroll' as 'scroll',
    '@media (max-width: 768px)': {
      width: '100%',
      padding: '10px'
    },
    [`@media only screen and (max-width: ${width}px)`]: {
      height: '637px'
    }
  },
  sendbtn: {
    background: "#DAE7F1",
    borderRadius: '50%',
    padding: '10px',
    cursor: 'pointer'
  },
  createButtonView: {
    display: "flex",
    justifyContent: "center",
    padding: "10px 0px",
    marginBottom: 20,
  },
  roomListContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  listItemContainer: {
    width: 300,
    border: "2px solid #ccc",
    borderRadius: 10,
    padding: 15,
    marginBottom: 20,
  },
  modalContainer: {
    display: "flex",
    flexDirection: "row",
    position: "absolute" as "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 300,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  modalButtonView: {
    marginLeft: 10,
  },
  mutedChat: {
    backgroundColor: "lightgray"
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  messageList: {
    flexGrow: 1,
    overflow: 'auto' as 'auto',
    padding: '16px',
  },
  messages: {
    margin: '8px 0',
    padding: '16px',
    borderRadius: '8px',
    maxWidth: '60%',
    wordWrap: 'break-word' as 'break-word',
  },
  sentMessage: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    alignSelf: 'flex-end' as 'flex-end',
  },
  receivedMessage: {
    backgroundColor: '#e0e0e0',
    alignSelf: 'flex-start' as 'flex-start',
  },
  inputContainer: {
    display: 'flex',
    padding: '16px',
  },
  textField: {
    flexGrow: 1,
    marginRight: '16px',
  },
  converation: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  noData: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '28px',
  },
  mobileHeading: {
    color: '#FFFFFF',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    position: 'absolute' as 'absolute',
    left: '45%',
    right: '50%'
  },
  startchat: {
    color: '#94A3B8',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  mobilename: {
    color: '#fff',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  customButtonBlue: {
    backgroundColor: '#3f5060',
    color: '#fff',
    padding: "10px 10px",
    borderRadius: '7px',
    width: "100%",
    fontSize: "12px",
    fontWeight: 500,
    border: "none",
    outline: "none",
    cursor: "pointer"
  },
  customButtonGreen: {
    background: '#d5e886',
    color: '#000',
    padding: "10px 10px",
    borderRadius: '7px',
    width: "100%",
    fontSize: "12px",
    fontWeight: 500,
    border: "none",
    outline: "none",
    cursor: "pointer"
  },
  offerContainer: {
    width: "100%",
    padding: "0px",
    background: "#FFFFFF",
    display: "flex",
    justifyContent: "right",
    height: "100px"
  },

  jobTitleChat: {
    color: "#395169",
    fontSize: "12px",
    fontFamily: "Inter"
  },
  offerDropdown: {
    color: "white",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
  },
  menuList: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  }
};
// Customizable Area End
