// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
const config = require("../../../framework/src/config");
import { daysObj } from "./assets";
import storage from '../../../framework/src/StorageProvider';
import PostCreationController from "../../postcreation/src/PostCreationController.web";
import { AccountAttributes ,Account , AccountData} from "./SavedJobController";


interface AccountImage {
  id: number;
  url: string;
  type: string;
  filename: string;
}

export interface JobAttributesNew {
  id: number;
  job_title: string;
  job_description: string;
  organization: string;
  education_level: string;
  experience_required: number;
  street_address: string;
  city: string;
  state: string;
  zip_code: string;
  country: string;
  about_organization: string;
  location: string | null;
  job_status: string;
  role_name: string;
  posted_by: string;
  job_image: string;
  account_image: AccountImage;
  created_at: string;
  key_responsibilities: string | null;
  qualifications: string | null;
  benefits: string | null;
  posted_at: string;
  admin_email: string;
  post_created_at: string;
  job_applied_at: string | null;
  start_date: string;
  end_date: string;
  created_by: number;
  job_working_days: JobWorkingDay[];
}

interface JobData {
  id: string;
  type: string;
  attributes: JobAttributesNew;
}

interface Jobs {
  data: JobData[];
}

interface Job {
  jobs : Jobs
}

export interface Props {
  navigation: any;
  id: string;
}
export interface CardsDetails {
  id: string;
  attributes: JobAttributes;
  type: string;
}

export interface CardsDetailsData {
  data : CardsDetails[]
}

interface JobAttributes {
  job_description: string;
  id: number;
  organization: string;
  job_title: string;
  street_address: string;
  city: string;
  state: string;
  experience_required: string;
  zip_code: string;
  post_created_at: string;
  about_organization: string;
  location: string;
  key_responsibilities: string;
  role_name: string;
  country: string;
  job_image: string;
  account_image: AccountImage;
  posted_by: string;
  created_at: string;
  qualifications: string;
  benefits: string;
  admin_email: string;
  education_level: string;
  job_applied_at: string | null;
  posted_at: string;
}
interface AccountImage {
  type: string;
  id: number;
  filename: string;
  url: string;
}

export interface JobWorkingDay {
  working_day_id: number;
  id: number;
  end_time: string;
  full_day: boolean;
  start_time: string;
}

interface Details {
  job_title: string;
  job_description: string;
  account_image:{url:string};
  organization: string;
  education_level: string;
  experience_required: string;
  street_address: string;
  city: string;
  state: string;
  zip_code: string;
  country: string;
  about_organization: string;
  posted_by: string;
  created_at: string;
  admin_email: string;
  id: number | null;
  start_date:string,
  end_date : string,
  job_working_days: JobWorkingDay[];
}

interface S {
// Customizable Area Start
  isApplied: boolean;
  isSnackbarOpen: boolean;
  snackbarMsg: string;
  isAppliedBtnClicked: boolean;
  details: Details;
  cardId: number;
  cardsList: CardsDetails[]
  inviteFriend: boolean;
  alreadyApplied:boolean;
  role: any;
  openPersonalInfo: boolean;
  editModeAcc: boolean;
  editModeAdd: boolean;
  firstPhoneNumber: string;
  phoneNum: string;
  openResume: boolean;
  anchorEl: null | HTMLElement;
  openIntroduction: boolean;
  introductionValueBox: boolean;
  isIntorEditClicked: boolean;
  resumeOpen: boolean;
  preferenceOpen: boolean;
  certificationOpen: boolean;
  availabilityOpen: boolean;
  anchorElCertificate: HTMLElement | null;
  teacherDetails: AccountAttributes;
  isAppliedTeacher:boolean,
  workingDays: string[]; 
  availabilty :any[];
  savedTeachers:number[],
  savedJobs :number[],
  days :string[],
  posts:JobData[],
  loader:boolean,
  isMobileView:boolean
  // Customizable Area End
}

interface SS {
  id: string;
}

export const configJSON = require("./config");

export default class JobListingDetailsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCreateConverstationId: string = '';
  getDetailsId: string = '';
  loginInterestedJobsId: string = '';
  appylyJobId: string = '';
  saveJodId: string = '';
  unsavejobID: string = '';
  personalInfoRef = React.createRef<HTMLDivElement>();
  resumeRef = React.createRef<HTMLDivElement>();
  preferenceRef = React.createRef<HTMLDivElement>();
  certificationRef = React.createRef<HTMLDivElement>();
  availabilityRef = React.createRef<HTMLDivElement>();
  getTeacherDetailId: string = "";
  getIntroId: string = '';
  savedJobList:string='';
  getEduId: string = '';
  getWorkExpId: string = '';
  savedTeacherList:string='';
  unsaveidFromTeacher:string='';
  saveTeacherId:string='';
  deleteJobId:string='';
  Obj:PostCreationController;
  getMyJob:string=''
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.Obj = new PostCreationController(props)

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionRequestMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      isApplied: false,
      isSnackbarOpen: false,
      snackbarMsg: '',
      isAppliedBtnClicked: false,
      details:{
        job_title: "",
        job_description: "",
        organization: "",
        education_level: "",
        experience_required: "",
        street_address: "",
        city: "",
        state: "",
        zip_code: "",
        country: "",
        about_organization: "",
        posted_by:'',
        created_at:'',
        admin_email:'',
        id:null,
        start_date:'',
        end_date:'',
        job_working_days:[],
        account_image:{url:""}
      },
      cardId:0,
      cardsList:[],
      inviteFriend:false,
      alreadyApplied:false,
      role: "",
      openPersonalInfo: false,
      editModeAcc: false,
      editModeAdd: false,
      firstPhoneNumber: "",
      phoneNum: "",
      openResume: false,
      anchorEl: null,
      openIntroduction: false,
      introductionValueBox: false,
      isIntorEditClicked: false,
      resumeOpen: false,
      preferenceOpen: false,
      certificationOpen: false,
      availabilityOpen: false,
      anchorElCertificate: null,
      teacherDetails: {
        id: 0,
        activated: false,
        email: '',
        first_name: '',
        last_name: '',
        full_phone_number: '',
        password: null,
        member_id: null,
        organization: null,
        street_address: '',
        city: '',
        state: '',
        zip_code: 0,
        country: '',
        no_of_employees: null,
        program_type: null,
        preferred_travel_distance: 0,
        program_capacity: null,
        about_us: null,
        allow_sms_notification: false,
        sms_recommendation: false,
        role: {
          id: 0,
          created_at: '',
          updated_at: '',
          name: '',
        },
        total_experience: 0,
        profile_image: {
          filename:'',
          id:0,
          size:'',
          type:'',
          url:''
        },
        upload_resumes: '',
        letters_of_interests: '',
        credentials: '',
        transcripts: '',
        note_pages: '',
        letter_of_reference_emails: '',
        country_code: null,
        phone_number: null,
        preferred_modality_ids: [],
        preferred_modality_name: [],
        preferred_age_group_ids: [],
        preferred_age_group_name: [],
        preferred_working_days_ids: [],
        preferred_working_days_name: [],
        preferred_nature_of_jobs_ids: [],
        preferred_nature_of_jobs_name: [],
        preferred_time_shifts_ids: [],
        preferred_time_shifts_name: [],
        document_sharings_ids: [],
        document_sharings_name: [],
        created_at: '',
        recent_work_experience: {
          position: '',
        },
        qualifications: [],
        about: {
          id: 0,
          description: '',
          created_at: '',
          updated_at: '',
        },
        work_experiences: [],
        skills: [],
        teaching_interests: [],
        certifications: {
          upload_resumes: [],
          letters_of_interests: [],
          credentials: [],
          transcripts: [],
          note_pages: [],
          letter_of_reference_emails: [],
          certificates: [],
        },
        job_preferences: {
          preferred_modality_ids: [],
          preferred_modality_names: [],
          preferred_age_group_ids: [],
          preferred_age_group_names: [],
          preferred_working_days_ids: [],
          preferred_working_days_names: [],
          preferred_nature_of_jobs_ids: [],
          preferred_nature_of_jobs_names: [],
          preferred_time_shifts_ids: [],
          preferred_time_shifts_names: [],
          document_sharings_ids: [],
          document_sharings_names: [],
          preferred_travel_distance: 0,
        },
        account_working_day_times: [],
      },
      workingDays: [
        '',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ],
      availabilty:[],
      isAppliedTeacher:false,
      savedTeachers:[],
      savedJobs:[],
      days:[
        "",
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
      ],
      posts:[],
      loader:false,
      isMobileView: window.innerWidth <= 571
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);
    const apiRequestCallId=message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));     
      this.handleApiResponse(apiRequestCallId ,responseJson )     
      this.handleApiResponseJob(apiRequestCallId , responseJson)  
      this.getApiJobs(responseJson , apiRequestCallId)
      if ( this.getCreateConverstationId==apiRequestCallId) {
        this.setResponse(responseJson?.conversation_sid , responseJson?.data?.conversation_sid)
        this.sendMessage()
      }
      if(this.getDetailsId==apiRequestCallId){
        this.setState({details:responseJson.data.attributes})
      }
      if(this.loginInterestedJobsId==apiRequestCallId){
        this.setState({cardsList:responseJson.jobs.data})
      }
      if (this.getTeacherDetailId == apiRequestCallId) {
        this.setState({
        })
        this.setState({
           teacherDetails: responseJson?.data?.attributes ,
           availabilty : this.availability(responseJson?.data?.attributes)
        })
        this.setState({
          loader:false
        })
      }
      if(apiRequestCallId==this.appylyJobId){
        if(responseJson.error=='You have already applied for this job')
          {this.setState({alreadyApplied:true})}
        else{
          this.getLoginInterestedJobs()
          this.setState({isAppliedBtnClicked:true})
        }
      }
    }
  }

  setResponse = (cid:string , cid2:string)=>{
    storage.set("conversation_sid",cid || cid2)
  }
  getApiJobs = (responseJson:Job , apiRequestCallId :string)=>{
    if (apiRequestCallId  == this.getMyJob) {
      this.setState({
        posts:responseJson.jobs.data 
      })
    }
    if(apiRequestCallId == this.deleteJobId){
      this.Obj.getMyJobList()
      this.goBack('JobCreated')
    }
  }

  isSaveOrUnsaveRequest(apiRequestCallId:string) {
    return [this.unsaveidFromTeacher, this.saveTeacherId].includes(apiRequestCallId);
  }

  isSaveOrUnsaveRequestJob(apiRequestCallId:string) {
    return [this.saveJodId, this.unsavejobID].includes(apiRequestCallId);
  }

  isSavedTeacherListResponse(apiRequestCallId:string) {
    return this.savedTeacherList === apiRequestCallId;
  }

  isSavedJObListResponse(apiRequestCallId:string) {
    return this.savedJobList === apiRequestCallId;
  }
  updateSavedTeachers(responseJson:AccountData) {
    const savedTeachers = responseJson?.data.map((item: Account) => item.attributes.id);
    this.setState({ savedTeachers });
  }

  updateSavedJob(responseJson:CardsDetailsData) {
    const savedJobs = responseJson?.data.map((item: CardsDetails) => item.attributes.id);
    this.setState({ savedJobs });
  }

  handleApiResponse(apiRequestCallId:string, responseJson:AccountData) {
    if (this.isSaveOrUnsaveRequest(apiRequestCallId)) {
      this.getSavedTeacherList();
    }
  
    if (this.isSavedTeacherListResponse(apiRequestCallId)) {
      this.updateSavedTeachers(responseJson);
    }
  }

  handleApiResponseJob(apiRequestCallId:string, responseJson:CardsDetailsData) {
    if (this.isSaveOrUnsaveRequestJob(apiRequestCallId)) {
      this.getSavedJobList();
    }
  
    if (this.isSavedJObListResponse(apiRequestCallId)) {
      this.updateSavedJob(responseJson);
    }
  }

  availability = (data:any) =>{
    const output = data.preferred_working_days_ids.map((dayId:any) => {
      const dayObj = daysObj.find(day => day.working_day_id === dayId);
      const timeObj = data.account_working_day_times.find((time:any) => time.working_day_id === dayId);
    
      return timeObj
        ? { ...timeObj, day_name: dayObj?.day_name }
        : { working_day_id: dayObj?.working_day_id, day_name: dayObj?.day_name  , full_day:true };
    });
    return output
  }

  async componentWillUnmount() {
    window.removeEventListener("resize", this.handleMobileView);
  }
  handleMobileView = () => {
    this.setState({ isMobileView: window.innerWidth <= 571 });
  };

  handleSavedUnsaved = async (isApplied: boolean ,id:number) => {
    if(isApplied){
      const header = {
        "Content-Type": 'application/json',
        token: await storage.get("loginToken"),
      };
  
      const setIntroMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.saveJodId = setIntroMsg.messageId;
  
      setIntroMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_joblisting/job_creations/${id}/save`
      );
  
      setIntroMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      setIntroMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
  
      runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
    }
    else{
      const header = {
        "Content-Type": 'application/json',
        token: await storage.get("loginToken"),
      };
  
      const setIntroMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.unsavejobID = setIntroMsg.messageId;
  
      setIntroMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_joblisting/job_creations/${id}/unsave`
      );
  
      setIntroMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      setIntroMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "DELETE"
      );
  
      runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
    }
    this.setState({ 
      isApplied: isApplied,
      isSnackbarOpen: true,
      snackbarMsg: isApplied ? configJSON.jobPostSavedSnackbarTxt : configJSON.jobPostRemovedSnackbarTxt
    })
  }

  handleSaveUnsaveTeacher = async (isApplied: boolean , id:number) => {
    if(isApplied){
      const header = {
        "Content-Type": 'application/json',
        token: await storage.get("loginToken"),
      };
  
      const setIntroMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.saveTeacherId = setIntroMsg.messageId;
  
      setIntroMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/account_block/accounts/${id}/save_substitute_teacher`
      );
  
      setIntroMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      setIntroMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
  
      runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
    }
    else{
      this.unsaveidFromTeacher = await this.handleUnsaveTeacher(id)
    }
    this.setState({ 
      isAppliedTeacher: isApplied,
      isSnackbarOpen: true,
      snackbarMsg: isApplied ? configJSON.teacherSavedSnackbarTxt : configJSON.teacherRemovedSnackbarTxt
    })
  }

  handleUnsaveTeacher = async (id: number) => {
    const header = {"Content-Type": 'application/json',token: await storage.get("loginToken"), };
    const setIntroMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const messageId = setIntroMsg.messageId;
    setIntroMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),`/account_block/accounts/${id}/unsave_substitute_teacher`);
    setIntroMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    setIntroMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage),"DELETE");
    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
    return messageId;
  };

  getSavedTeacherList = async ()=>{
    const header = {
      "Content-Type": 'application/json',
      token: await storage.get("loginToken"),
    };

    const setIntroMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.savedTeacherList = setIntroMsg.messageId;

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts/saved_substitute_teachers`
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
  }
getSavedJobList = async ()=>{
    const header = {
      "Content-Type": 'application/json',
      token: await storage.get("loginToken"),
    };
    const setIntroMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.savedJobList = setIntroMsg.messageId;
    setIntroMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_joblisting/job_creations/saved_jobs`
    );
    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),"GET");
    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
  }

  closeSnackbar = () => {
    this.setState({ isSnackbarOpen: false, alreadyApplied:false})
  }

  handleApplyForJob = () => {
    this.getLoginInterestedJobs()
    this.appylyForJobApi()
    window.scrollTo(0, 0)
  }

  handleNavigation = (navigateTo: string ,flag:boolean) => {
    if(flag){
      storage.set("afterAppliedJob" , true)
    }
    else{
      storage.set("afterAppliedJob" , false)
    }
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      navigateTo
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  handleBack = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'JobListing'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  getChatList = async () => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: await storage.get('loginToken')
      
    };
    const bodyData ={
      "friendly_name": this.state.details.posted_by,
      "school_admin_email": this.state.details.admin_email
    }
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCreateConverstationId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCreateIdEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTeacherListTeacher = async (fname:string , email:string) =>{
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: await storage.get('loginToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCreateConverstationId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCreateIdEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        "friendly_name": fname,
        "substitute_teacher_email": email
      })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  sendMessage=()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData( getName(MessageEnum.NavigationTargetMessage), 'Chat' );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message( getName(MessageEnum.NavigationPayLoadMessage) );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  getJobDeatils=async (cardId:number|null)=>{
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token : await storage.get("loginToken")
    };

    const setIntroMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    

    this.getDetailsId = setIntroMsg.messageId;

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_joblisting/job_creations/${cardId}`
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.setState({
      loader:true
    })
    const cardId=await storage.get('jobId')
    const teacherId= await storage.get('profileId')
    this.setState({
      cardId :parseInt(cardId),
    })
    this.setState({
      role: await storage.get("role")
    })
    this.getJobDeatils(cardId)
    this.getTeacherDetails(teacherId)
    this.getSavedTeacherList()
    this.getSavedJobList();
    this.getMyJobList()
  }

  getLoginInterestedJobs = async () => {
    const token = await storage.get("loginToken");

    const header = { token };

    const getLoginRecentJobsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginInterestedJobsId = getLoginRecentJobsMsg.messageId;

    getLoginRecentJobsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_joblisting/job_creations/jobs_recently_added`
    );

    getLoginRecentJobsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getLoginRecentJobsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );

    runEngine.sendMessage(getLoginRecentJobsMsg.id, getLoginRecentJobsMsg);
  };

  browseMoreJob=()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData( getName(MessageEnum.NavigationTargetMessage), 'JobListing' );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message( getName(MessageEnum.NavigationPayLoadMessage) );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  appylyForJobApi=async ()=>{
    const header = {
      "Content-Type": 'application/json',
      token: await storage.get("loginToken"),
    };
    const body ={
      "job_application": {
        "status": "applied"
      }
    }  

    const setIntroMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.appylyJobId = setIntroMsg.messageId;

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_joblisting/job_creations/${this.state.details.id}/apply_for_job`
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
  }

  shortString=(value:string)=>{
      if (value.length <= 100) {return value;} return value.slice(0, 100) + '...';
  }

  inviteclick=()=>{
    this.setState({inviteFriend:true})
  }

  scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  resumeLink = () => { this.scrollToSection(this.resumeRef); this.setState({ resumeOpen: true }) }
  certificateLink = () => { this.scrollToSection(this.certificationRef); this.setState({ certificationOpen: true }) }
  preferLink = () => { this.scrollToSection(this.preferenceRef); this.setState({ preferenceOpen: true }) }
  availabilityLink = () => { this.scrollToSection(this.availabilityRef); this.setState({ availabilityOpen: true }) }
  personalLink = () => { this.scrollToSection(this.personalInfoRef); this.setState({ preferenceOpen: true }) }

  handlePersonalInfo = () => {
    this.setState((prevState) => ({
      openPersonalInfo: !prevState.openPersonalInfo,
    }));
  };
  handleResumeToggle = () => {
    this.setState((prevState) => ({
      resumeOpen: !prevState.resumeOpen
    }));
  };

  handlePreferenceToggle = () => {
    this.setState((prevState) => ({
      preferenceOpen: !prevState.preferenceOpen
    }));
  };

  handleCertificationToggle = () => {
    this.setState((prevState) => ({
      certificationOpen: !prevState.certificationOpen
    }));
  };

  handleAvailabilityToggle = () => {
    this.setState((prevState) => ({
      availabilityOpen: !prevState.availabilityOpen
    }));
  };

  handleViewClick = (fileUrl: string) => {
    this.setState({ anchorElCertificate: null })
    window.open(fileUrl, '_blank')
  };

  getTeacherDetails = async (cardId: any) => {
    this.setState({
      loader:true
    })
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: await storage.get("loginToken")
    };

    const setIntroMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTeacherDetailId = setIntroMsg.messageId;

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_custom_forms/custom_forms/${cardId}/show_profile`
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
  }

  handleClose = ()=>{
    this.setState({
      anchorEl:null
    })
  }

  handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleEditClick = (data:Details)=>{
    storage.set('jobedit' , JSON.stringify(data))
    this.goBack('PostEdit')
  }

   goBack(to:string){
      const message: Message = new Message(getName(MessageEnum.NavigationMessage))
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        to
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(message);
      this.Obj.getMyJobList()
      this.componentDidMount()
  }

  async handleDeleteClick(id:number|null){
    const header = {
      "Content-Type": 'application/json',
      token: await storage.get("loginToken"),
    };
    const deleteMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteJobId = deleteMsg.messageId;
    deleteMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_joblisting/job_creations/${id}/delete_job`
    );
    deleteMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    deleteMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deleteMsg.id, deleteMsg);
  }
  async getMyJobList (){
    const token = await storage.get("loginToken")
    const header = {
      token: token};
      const getMyJobs = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMyJob = getMyJobs.messageId;

    getMyJobs.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_joblisting/job_creations/user_jobs`
    );
    getMyJobs.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getMyJobs.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`);
    runEngine.sendMessage(getMyJobs.id, getMyJobs);
  }

  formatDate(dateString:string) {
    if (!dateString) {
      return null
    }
    const [day, month, year] = dateString.split('/').map(Number);
    const fullYear = year >= 50 ? 1900 + year : 2000 + year;
    const date = new Date(fullYear, month - 1, day);
  
    return date.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' });
  }

  viewDetail = (index: number) => {
      storage.set('jobId',index)
      window.location.reload()
  };

  // Customizable Area End
}

// Customizable Area End
