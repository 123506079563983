import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  filename: File | null;
  defaultName: string;
  onChangeName: string;
  handleDocumentChange: (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  errorMessage:string,
  handleBlur: (event: React.FocusEvent<HTMLElement>) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedFile: File | { name: string; type?: string; uri?: string } | null;
  sharedId: string;
  token: string;
  fieldError: false;
  fileInBase64: string;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface DocumentObject {
  data: string;
  filename?: string;
  content_type?: string | null;
}
// Customizable Area End

export default class DocumentDistributionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  uploadDocumentApiCallId!: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.SessionResponseToken),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedFile: null,
      sharedId: "",
      token: "",
      fieldError: false,
      fileInBase64: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };
  async receive(from: string, message: Message) {

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.uploadDocumentApiCallId != null &&
      this.uploadDocumentApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.data) {
        this.setState({ sharedId: "", selectedFile: null });
        alert("Document Upload Successfully");
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        if (errorResponse) {
          console.log("Invalid Token");
          this.parseApiCatchErrorResponse(errorResponse);
        }
      }
    }
  }

  handleInput = (inputKey: string, value: string) => {
    this.setState((current) => ({ ...current, [inputKey]: value }));
  };

  navigateToSharedDocumentList = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "SharedDocument"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0] as File;
      this.setState({ selectedFile: file });
    }
  };

  handleSelectFile = async () => {
    const DocumentPicker = require("react-native-document-picker");
    const resData = await DocumentPicker.pick({
      type: [DocumentPicker.types.allFiles],
    });

    if (!resData) {
      return;
    }

    this.setState({
      selectedFile: {
        name: resData[0].name,
        type: resData[0].type,
        uri: resData[0].uri,
      },
      fileInBase64: resData[0].uri,
    });
  };

  getBase64 = (file: File): Promise<string> => {
    return new Promise((resolve) => {
      let baseURL: string | ArrayBuffer | null = "";
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;
        const startpoint = (baseURL as string).search("data");
        const endpoint = (baseURL as string).search("base64,");
        const strUrl = (baseURL as string).slice(startpoint, endpoint + 7);
        baseURL = (baseURL as string).replace(strUrl, "");
        resolve(baseURL);
      };
    });
  };

  onFileUpload = async () => {
    if (this.isPlatformWeb()) {
      const result = await this.getBase64(this.state.selectedFile as File);

      const document = {
        data: result,
        filename: this.state.selectedFile?.name,
        content_type: this.state.selectedFile?.type,
      };
      return this.sendRequest(document);
    } else {
      const fsResponse = require("react-native-fs");
      const fileInBase64 = await fsResponse.readFile(
        this.state.fileInBase64,
        "base64"
      );
      const document = {
        data: fileInBase64,
        filename: this.state.selectedFile?.name,
        content_type: this.state.selectedFile?.type,
      };
      this.sendRequest(document);
    }
  };

  sendRequest = (documentObj: DocumentObject) => {
    const dataObj = {
      shared_to_id: Number.parseInt(this.state.sharedId),
      post_id: null,
      documents: [documentObj],
    };

    const header = {
      token: this.state.token,
      "Content-Type": configJSON.ApiContentType,
    };

    const httpBody = {
      data:dataObj,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.uploadDocumentApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.DocumentUploadApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DocumentUploadApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
