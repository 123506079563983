import React from 'react';
import { Box, Button, Drawer, Typography, styled } from '@material-ui/core';
import storage from "../../framework/src/StorageProvider"
import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import { BlockComponent } from "../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";

interface DrawerComponentProps {
    navigation: any;
    isOpen: boolean;
    toggleDrawer: (isOpen: boolean) => void;
}

interface DrawerComponentState {
    token: string | null;
    userId: string | null;
    userRoleId: any;
    totalCredits:number;
}
interface SS {
    id: any
  }
class DrawerComponent extends BlockComponent<DrawerComponentProps, DrawerComponentState,SS> {
    constructor(props: DrawerComponentProps) {
        super(props);
        this.state = {
            token: null,
            userId: null,
            userRoleId: null,
            totalCredits:0,
        };
    }

    async componentDidMount() {
        const token = await storage.get("loginToken");
        const userId = await storage.get("loginId");
        const role: any = await storage.get("role");

        this.setState({
            token: token,
            userId: userId,
            userRoleId: role,
            totalCredits:await storage.get('totalCredits')
        });
    }
    handleLogin = () => {
        const keysToRemove = [
            "workingDays",
            "userData",
            "typedPassward",
            "typedEmail",
            "totalCredits",
            "token",
            "skilsss",
            "skillIds",
            "selectedRole",
            "saveUserInfo",
            "role",
            "profilepic",
            "profileImage",
            "profileId",
            "phone",
            "otp type",
            "loginToken",
            "loginId",
            "login-token",
            "lname",
            "jobedit",
            "jobId",
            "isSubmited",
            "isSplashVisited",
            "isSmsNotiChecked",
            "isRecommendationsChecked",
            "isNotificationChecked",
            "intrestsss",
            "f_name",
            "fistLetter",
            "filled",
            "email",
            "conversation_sid",
            "authToken",
            "afterAppliedJob",
            "accountId",
            "schoolid"
        ];
        
        keysToRemove.forEach(key => storage.remove(key));
        this.props.navigation.navigate("EmailAccountLoginBlock")
    }
    handleSignup = () => {
        this.props.navigation.navigate("EmailAccountRegistration")
    }
    handleCreatePost = () => {
        this.props.navigation.navigate("PostCreation")
    }
    navigateSetting = () => {
        this.props.navigation.navigate("Settings2")
    }
    handleLogout = () => {
        storage.remove("loginToken")
        localStorage.removeItem('login-token')
        this.props.navigation.navigate("EmailAccountLoginBlock")
    }
    navigateTransaction=()=>{
        this.props.navigation.navigate("Transaction")
    }
    navigateToSavedTeacher=()=>{
        this.props.navigation.navigate("SavedTeachers")
    }
    navigateToJobCreated=()=>{
        this.props.navigation.navigate("JobCreated")
    }
    navigateApplications=()=>{
        this.props.navigation.navigate("MyJobsApplication")
    }
    navigateSavedJob=()=>{
        this.props.navigation.navigate("SavedJob")
    }
    navigatePrivacyFaq = (pageName: string) => {
        storage.set('navigateTo','JobListing')
        const message: Message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData(
          getName(MessageEnum.NavigationTargetMessage),
          'PrivacyFaq'
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), { ...this.props, pageName })
        const raiseMessage: Message = new Message(
          getName(MessageEnum.NavigationPayLoadMessage)
        );
        raiseMessage.addData(getName(MessageEnum.SessionResponseData), { pageName });
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(message);
      }  
      navigateAboutUs=()=>{
        this.props.navigation.navigate("AboutUs")
      }  
  
     render() {
        const { isOpen, toggleDrawer } = this.props;

        return (
            <CustomDrawer
                anchor="right"
                open={isOpen}
                onClose={() => toggleDrawer(false)}
            >
                <div
                    role="presentation"
                    onClick={() => toggleDrawer(false)}
                    onKeyDown={() => toggleDrawer(false)}
                >
                    <img src={require("./image_PCC_Logos_.png")} style={{ width: '144px' }} />
                    <Box style={{ marginTop: '20px' }}>
                        {!this.state.token && <div style={{display:'grid'}}>
                            <Typography onClick={()=>this.navigatePrivacyFaq('Faq')} style={webStyle.textstyle}>FAQs</Typography>
                            <Typography onClick={this.navigateAboutUs} style={{...webStyle.textstyle,padding:'18px 6px'}}>About us</Typography>
                            <Button data-test-id="handleLogin" onClick={this.handleLogin} style={webStyle.loginBtn}>Login</Button>
                            <Button data-test-id="handleSignup" onClick={this.handleSignup} style={webStyle.signBtn}>Sign up</Button>
                        </div>}
                        {this.state.token && this.state.userRoleId == 1 && <>
                            <Typography onClick={this.navigateToSavedTeacher} style={{...webStyle.textstyle,padding:'12px 6px'}}>Saved Teachers</Typography>
                            <Typography onClick={this.navigateToJobCreated} style={{...webStyle.textstyle,padding:'12px 6px'}}>Jobs Created</Typography>
                            <Box onClick={this.handleCreatePost}>
                                <Typography style={webStyle.textstyle}>+ New Post</Typography>
                            </Box>
                            <Box onClick={this.navigateTransaction} style={{ display: 'flex', gap: '0px', marginTop: '2px',cursor:'pointer' ,alignItems:"center" }}>
                                <Typography style={webStyle.textstyle}>{this.state.totalCredits}</Typography>
                                <img src={require("./textlabel_.png")} style={{ width: '25px', height: '25px'}} />
                            </Box>
                        </>}
                        {this.state.token && this.state.userRoleId == 2 && <>
                            <Box onClick={this.navigateApplications}>
                                <Typography style={webStyle.textstyle}>My Job Application</Typography>
                            </Box>
                            <Box onClick={this.navigateSavedJob}>
                                <Typography style={webStyle.textstyle}>Saved Jobs</Typography>
                            </Box>
                        </>}
                        {this.state.token && <>
                            <Typography onClick={this.navigateSetting} style={{ ...webStyle.textstyle }}>Settings</Typography>
                            <Typography onClick={this.handleLogout} style={{ ...webStyle.textstyle, color: "#DC2626"}}>Logout</Typography>
                        </>}
                    </Box>
                </div>
            </CustomDrawer>
        );
    }
}

export default DrawerComponent;

const CustomDrawer = styled(Drawer)({
    "& .MuiDrawer-paperAnchorRight": {
        padding: '20px',
    }
})
const webStyle = {
    loginBtn: {
        color: "#395169",
        border: "1px solid #395169",
        borderRadius: "8px",
        padding: "10px 32px",
        fontFamily: "Inter",
        textTransform: "none" as "none",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        cursor:'pointer',
    },
    signBtn: {
        color: "#FFFFFF",
        border: "1px solid #395169",
        padding: "10px 32px",
        borderRadius: "8px",
        background: "#395169",
        fontFamily: "Inter",
        fontSize: "14px",
        textTransform: "none" as "none",
        fontWeight: 700,
        lineHeight: "22px",
        marginTop:'20px',
        cursor:'pointer'
    },
    textstyle: {
        color: "#1E293B",
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "22px",
        padding: "12px 6px",
        cursor: "pointer"
    },
}