import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import storage from '../../../framework/src/StorageProvider';
import firebase from "firebase";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  emailErrorMsg: string;
  passwordErrorMsg: string;
  showAlert: boolean;
  alertMsg: string;
  isSplashVisited: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  passwordReg: RegExp;
  emailReg: RegExp;
  labelTitle: string = "";
  emailLoginApiId: string = "";
  setDeviceTokenid:string=''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      emailErrorMsg: '',
      passwordErrorMsg: '',
      showAlert: false,
      alertMsg: '',
      isSplashVisited: false,
    };

    this.passwordReg = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{8,}$/);
    this.emailReg = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
    this.labelTitle = configJSON.labelTitle;
    this.requestPermission = this.requestPermission.bind(this);
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    const isMobileWidth = window.innerWidth <= 414;
    const isVisited = await storage.get("isSplashVisited");

    if(isMobileWidth && !isVisited){
      this.setState({isSplashVisited: true})
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleHomepage=(to:string)=>{
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      to
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      raiseMessage
    );
    this.send(message);
  }
  handleResponse(responseJson: { errors : {
    failed_login: string; 
    account_id: number; 
    email: string; 
    full_phone_number: string; 
  }} & { meta: { token: string; }; data: { id: any; attributes: { first_name :string, last_name:string,role: any;profile_image:{url:string}  }; }; } , apiRequestCallId: string) {
    if (responseJson?.errors?.failed_login=="Account not verified") {
      this.handleNullData(responseJson)
    }
    else {
      this.emailLoginApi(apiRequestCallId, responseJson);
    }
  }
  handleNullData = (responseJson : any)=>{
    if (responseJson?.errors?.full_phone_number !== '') {
      storage.set("phone", responseJson?.errors?.full_phone_number);
      storage.set("email", responseJson?.errors?.email);
      storage.set('IsApplied', false)
      storage.remove('IsApproved')
      this.handleEmailPhoneVerificationPage('Verification');
    }else{
      storage.set("accountId" , responseJson?.errors?.account_id)
      this.handleEmailPhoneVerificationPage('Rolesandpermissions2');
    }
  }
  roleSelectionPage=()=>{
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Rolesandpermissions2"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      raiseMessage
    );
    this.send(message);
  }
  emailLoginApi= async (apiRequestCallId: string,responseJson: { meta: { token: string; }; data: { id: any; attributes: { first_name :string, last_name:string,role: any;profile_image:{url:string} }; }; })=>{
    if(apiRequestCallId === this.emailLoginApiId){
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        this.saveLoggedInUserData(responseJson);
        this.sendLoginSuccessMessage();
        storage.remove('IsApproved')
        storage.set("loginToken",responseJson.meta.token )
        storage.set("login-token",responseJson.meta.token )
        storage.set("loginId",responseJson.data.id)
        storage.set("role" , responseJson.data.attributes.role.id)
        storage.set("profileImage" ,responseJson.data.attributes?.profile_image?.url)
        storage.set("f_name" , responseJson.data.attributes.first_name)
        storage.set("lname" , responseJson.data.attributes.last_name)
        storage.set('fistLetter',responseJson.data.attributes.first_name.charAt(0).toUpperCase())
        storage.set("isUser" , true)
        const roleid= responseJson.data.attributes.role.id;
        if(roleid==null){
          this.roleSelectionPage()
        }else {
          this.requestPermission(responseJson.meta.token)
            if (roleid == 1) {
              this.handleHomepage('TeacherListing')
            }else{
              this.handleHomepage('JobListing')
            }
        }
      } else {
        
        this.loginApiErrorMessage(responseJson);
        this.sendLoginFailMessage();
      }
    }
  }
  setVisited = () => {
    setStorageData("isSplashVisited", JSON.stringify(true))
    this.setState({isSplashVisited: false})
  }

  async requestPermission(token:string) {
    const messaging = firebase.messaging();
    try {
      const currentToken = await messaging.getToken();
      if (currentToken) {
        await messaging.deleteToken();
      }
      const newToken = await messaging.getToken();
      this.setDeviceToken(newToken , token);
    } catch (err) {
      console.error("Error generating device token", err);
    }
  }
  setDeviceToken = async (fcmToken: string , token:string) => {
    const header = {
      token: token,
    };
    const formData = new FormData();
    formData.append("data[device_tokens][]", fcmToken);
  
    const setNewEmail = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.setDeviceTokenid = setNewEmail.messageId;
  
    setNewEmail.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_push_notifications/push_notifications/add_device`
    );
  
    setNewEmail.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
  
    setNewEmail.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
  
    setNewEmail.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
  
    runEngine.sendMessage(setNewEmail.id, setNewEmail);
  }

  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  goToSignupPage() {
    const navigateMessage: Message = new Message(
      getName(MessageEnum.NavigateEmailSignUpMessage)
    );
    navigateMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMessage);
  }

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  handleEmailChange = (text: string) => {
    this.setState({
      email: text,
      emailErrorMsg: text ? "" : configJSON.errorMsgPleaseEnterEmail,
    });
  };

  handlePasswordChange = (text: string) => {
    this.setState({
      password: text,
      passwordErrorMsg: text ? "" : `${configJSON.errorMsgPleaseEnterPassword} ${configJSON.passTxt}${configJSON.wordTxt}${configJSON.dotTxt}`,
    });
  };

  handleEmailPhoneVerificationPage(to:string){
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage),to);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      raiseMessage
    );
    this.send(message);
}

  handleEmailLogin() {
    this.setState({
      showAlert: false,
    })
    if (!this.state.email || !this.state.password) {
      let e_error  = this.state.email?"": configJSON.errorMsgPleaseEnterEmail
      let  p_error = this.state.password ? "" : `${configJSON.errorMsgPleaseEnterPassword} ${configJSON.passTxt}${configJSON.wordTxt}${configJSON.dotTxt}`
      this.setState({
        emailErrorMsg: e_error,
        passwordErrorMsg: p_error,
      });
      return false;
    }
    
    if (!this.emailReg.test(this.state.email)) {
      this.setState({ 
        emailErrorMsg: configJSON.errorMsgInvalidEmail,
      });
    
      if (!this.state.password) {
        this.setState({
          passwordErrorMsg: `${configJSON.errorMsgPleaseEnterPassword} ${configJSON.passTxt}${configJSON.wordTxt}${configJSON.dotTxt}`
        });
      } else if (!this.passwordReg.test(this.state.password)) {
        this.setState({
          passwordErrorMsg: `${configJSON.starTxt}${configJSON.invalidTxt} ${configJSON.passTxt}${configJSON.wordTxt}${configJSON.commaTxt} ${configJSON.errorMsgInvalidPassword} ${configJSON.passTxt}${configJSON.wordTxt}${configJSON.dotTxt}`
        });
      }
    
      return false;
    }

    if (!this.state.password || !this.passwordReg.test(this.state.password)) {
      this.setState({
        passwordErrorMsg: !this.state.password ? `${configJSON.errorMsgPleaseEnterPassword} ${configJSON.passTxt}${configJSON.wordTxt}${configJSON.dotTxt}` :
                          `${configJSON.starTxt}${configJSON.invalidTxt} ${configJSON.passTxt}${configJSON.wordTxt}${configJSON.commaTxt} ${configJSON.errorMsgInvalidPassword} ${configJSON.passTxt}${configJSON.wordTxt}${configJSON.dotTxt}`
      });
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const emailData = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: emailData,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.emailLoginApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  gotToLandingPage=()=>{
    this.props.navigation.navigate("Home")
  }
  loginApiErrorMessage = (responseJson: any) => {
    let errorMessage = configJSON.somethingWentWrong

    if (responseJson?.errors) {
        errorMessage = typeof (responseJson.errors) === "string" ? responseJson.errors : responseJson.errors[0].failed_login || configJSON.somethingWentWrong;
    }

    if(errorMessage === 'Incorrect password'){
      this.setState({
        passwordErrorMsg: `${configJSON.starTxt}${configJSON.invalidTxt} ${configJSON.passTxt}${configJSON.wordTxt}${configJSON.commaTxt} ${configJSON.errorMsgInvalidPassword} ${configJSON.passTxt}${configJSON.wordTxt}${configJSON.dotTxt}`,
      });
    }else if(errorMessage=="Your Account is not Approved by admin") {
      storage.set('IsApproved',false)
      storage.remove('IsApplied')
      this.handleEmailPhoneVerificationPage('VerificationSentPending')

    }else{
      this.setState({
        alertMsg: errorMessage,
        showAlert: true,
      })
    }
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      if (!countryCode && userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true,
        });

        //@ts-ignore
        this.txtInputEmailProps.value = userName;

        //@ts-ignore
        this.txtInputPasswordProps.value = password;

        this.CustomCheckBoxProps.isChecked = true;
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if (
          apiRequestCallId === this.validationApiCallId &&
          responseJson !== undefined
        ) {
          var arrayholder = responseJson.data;

          if (arrayholder && arrayholder.length !== 0) {
            let regexData = arrayholder[0];

            if (regexData && regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        }

        if (apiRequestCallId === this.apiEmailLoginCallId) {
          if (responseJson && responseJson.meta && responseJson.meta.token) {
            runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
            this.saveLoggedInUserData(responseJson);
            this.sendLoginSuccessMessage();
            this.openInfoPage();
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
            this.sendLoginFailMessage();
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
        this.handleResponse(responseJson, apiRequestCallId)
      }
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
